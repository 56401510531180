<template>
  <!-- Page Header -->
  <!--
    Sidebar on Desktop
      Closed '' (no class)
      Opened 'lg:pl-64'
  -->
  <Sidebar
    :mobileSidebarOpen="mobileSidebarOpen"
    :desktopSidebarOpen="desktopSidebarOpen"
    @mobileSidebarClosed="mobileSidebarOpen = false"
  />

  <header
    id="page-header"
    class="z-1 sticky flex flex-none items-center h-16 bg-white shadow-sm top-0 right-0 left-0 dark:bg-gray-800"
  >
    <div class="flex justify-between max-w-10xl mx-auto px-4 lg:px-8 w-full">
      <!-- Left Section -->
      <div class="flex items-center space-x-2">
        <!-- Toggle Sidebar on Desktop -->
        <div class="hidden lg:block">
          <button
            @click="
              desktopSidebarOpen = !desktopSidebarOpen;
              emitDesktopSideBarStatus();
            "
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold rounded h-10 px-3 py-2 leading-5 text-sm border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
          >
            <svg
              class="hi-solid hi-menu-alt-1 inline-block w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <!-- END Toggle Sidebar on Desktop -->

        <!-- Toggle Sidebar on Mobile -->
        <div class="lg:hidden">
          <button
            @click="mobileSidebarOpen = !mobileSidebarOpen"
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold rounded h-10 px-3 py-2 leading-5 text-sm border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
          >
            <svg
              class="hi-solid hi-menu-alt-1 inline-block w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>

        <CommandPalette />
      </div>
      <!-- END Left Section -->
    </div>
  </header>

  <!-- END Page Header -->
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useAuth } from '../auth';
import { router } from '../routes';
import Sidebar from '@/components/Sidebar.vue';
import CommandPalette from '@/components/base/CommandPalette.vue';

const { user } = useAuth();

const emits = defineEmits<{
  (e: 'sidebar-desktop-status', desktopSideBarOpen: boolean): void;
}>();

const emitDesktopSideBarStatus = () => {
  emits('sidebar-desktop-status', desktopSidebarOpen.value);
};

const mobileSidebarOpen = ref(false);
const desktopSidebarOpen = ref(true);
</script>
