<template>
  <div
    :class="[
      'flex',
      'flex-row',
      'rounded',
      { 'form-input-changed': hasChanged },
    ]"
  >
    <input
      v-model="internalModel"
      class="form-input grow min-w-[180px] w-[180px]"
      type="datetime-local"
      id="form-elements-published"
      :placeholder="placeholder || ''"
    />
    <select
      v-model="timezoneSelected"
      class="shrink w-full rounded-r border-gray-200"
    >
      <option v-for="timezone in timezones" :value="timezone">
        {{ timezone }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DateTime } from 'luxon';

import { timezones } from './timezones';

const props = defineProps<{
  initialValue?: string | null | undefined;
  placeholder?: string | null;
  modelValue: string | null | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | undefined): void;
  (e: 'differs', value: boolean): void;
}>();

const timezoneSelected = ref(DateTime.local().zoneName);
const internalModel = ref(props.initialValue);
const hasChanged = computed(() => internalModel.value !== props.initialValue);

watch(props, () => {
  if (!internalModel.value) {
    internalModel.value = props.modelValue;
  }
});

watch([internalModel, timezoneSelected], (_) => {
  const dateToEmit = !internalModel.value
    ? null
    : DateTime.fromISO(internalModel.value, { zone: timezoneSelected.value })
        .toUTC()
        .toJSON();
  emit('update:modelValue', dateToEmit);
  emit('differs', hasChanged.value);
});
</script>
<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded-l px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply border focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
