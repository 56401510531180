<template>
  <template v-if="loading">Loading ...</template>

  <template v-else-if="!file">
    <error-display v-if="error" headerMessage="Error" :error="error" />
    <warning-display v-else headerMessage="Warning" warning="File Not Found" />
  </template>

  <template v-else-if="file">
    <div>
      <h4
        class="text-gray-400 uppercase text-semi-bold font-semibold text-sm tracking-widest"
      >
        File
      </h4>
      <h2 class="page-title">
        <em>{{ fileId }}</em>
      </h2>
    </div>

    <div class="flex flex-col rounded">
      <h2
        class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
      >
        File information
      </h2>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
      <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
        <div class="p-5 lg:p-6 flex-grow w-full h-full">
          <dl>
            <dt class="text-2xl font-semibold">
              {{ file?.originalName || 'Unknown' }}
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Original Name
            </dd>
          </dl>
        </div>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white"
        v-if="file?.created"
      >
        <div class="p-5 lg:p-6 flex-grow w-full h-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date :date="file.created" clickable />
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Creation Date
            </dd>
          </dl>
        </div>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white"
        v-if="file?.updated"
      >
        <div class="p-5 lg:p-6 flex-grow w-full h-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date :date="file.updated" clickable />
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Updated Date
            </dd>
          </dl>
        </div>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden"
        v-if="file?.derivedFrom?.id"
      >
        <router-link
          :to="{ name: 'file', params: { id: file.derivedFrom.id } }"
        >
          <div class="p-5 lg:p-6 flex-grow w-full h-full">
            <dl class="overflow-hidden">
              <dt class="text-xl font-semibold italic">
                {{ file.derivedFrom.id }}
              </dt>
              <dd
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Derived From
              </dd>
            </dl>
          </div>
        </router-link>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden"
        v-if="file?.nature"
      >
        <div class="p-5 lg:p-6 flex-grow w-full h-full">
          <dl>
            <dt class="text-2xl font-semibold">
              {{ file.nature }}
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Nature
            </dd>
          </dl>
        </div>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden"
        v-if="file?.namespace?.id && file?.namespace?.name"
      >
        <router-link
          :to="{ name: 'namespace', params: { id: file.namespace.id } }"
        >
          <div class="p-5 lg:p-6 flex-grow w-full h-full">
            <dl>
              <dt class="text-2xl font-semibold">
                {{ file.namespace.id }}
              </dt>
              <dd
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                {{ file.namespace.name }}
              </dd>
            </dl>
          </div>
        </router-link>
      </div>

      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden"
        v-if="file?.size"
      >
        <div class="p-5 lg:p-6 flex-grow w-full h-full">
          <dl>
            <dt class="text-2xl font-semibold">
              {{ readableFileSize(file.size) }}
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Size
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="flex flex-col rounded overflow-hidden pt-8">
      <div>
        <h2
          class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
        >
          Related Publication
        </h2>
      </div>
      <div
        class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5 lg:gap-8"
        v-for="entity of file.relatedEntities"
      >
        <div
          class="flex flex-col overflow-hidden rounded-lg bg-white dark:bg-gray-800 dark:text-gray-100"
          v-if="entity.__typename == 'Publication'"
        >
          <router-link :to="{ name: 'publication', params: { id: entity.id } }">
            <div class="w-full grow p-5 text-center lg:p-6">
              <div class="overflow-hidden rounded-md">
                <p v-if="entity?.isbn">
                  <b>ISBN: {{ entity.isbn }}</b>
                </p>
                <p>{{ entity?.metadata?.title }}</p>
                <p
                  v-if="entity?.metadata?.nature"
                  class="inline-flex rounded-full px-2 py-1 text-sm font-semibold leading-4"
                  :class="
                    colorAccordingToPublicationNature(entity.metadata.nature)
                  "
                >
                  {{ entity.metadata.nature }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="flex flex-col rounded shadow-sm overflow-hidden">
      <h2
        class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
      >
        Actions
      </h2>

      <div v-if="entity && entity.__typename == 'Publication'">
        <file-trigger-action
          :file="file"
          :entity-id="entity.id"
          :extracted-from-file-id="
            entity.extractedMetadata?.extractedFromFileId ?? null
          "
          :fileList="fileList"
          :disableAction="!canBeRepacked(file)"
          buttonSize="m"
        />
        <download-file-button :file="file" button-size="large" class="m-1" />
        <file-archival-button
          v-if="hasFileWriteRight"
          :file="file"
          button-size="large"
          class="m-1"
        />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { graphql } from '../../__generated__/gql';
import {
  colorAccordingToPublicationNature,
  readableFileSize,
} from '../../utils';
import { useAuth } from '../../auth';

import FileTriggerAction from './FileTriggerAction.vue';
import FileArchivalButton from './FileArchivalButton.vue';
import DownloadFileButton from './DownloadFileButton.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import WarningDisplay from '../WarningDisplay.vue';

const route = useRoute();
const fileId = computed(() => route.params.id as string);
const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();

const hasFileWriteRight = canWriteResourceTypeOnAtLeastOneNamespace('files');

const { result, loading, error } = useQuery(
  graphql(`
    query GetFile($id: ID!) {
      file(id: $id) {
        id
        originalName
        created
        updated
        nature
        mime
        location
        size
        derivedFrom {
          id
          nature
          originalName
        }
        namespace {
          id
          name
        }
        relatedEntities {
          ... on Publication {
            id
            extractedMetadata {
              extractedFromFileId
            }
            isbn
            metadata {
              title
              nature
            }
            files {
              edges {
                node {
                  id
                  nature
                  created
                  derivedFrom {
                    id
                  }
                }
              }
            }
          }
        }
        representations {
          __typename
        }
      }
    }
  `),
  () => ({ id: fileId.value })
);

const file = computed(() => {
  if (result?.value?.file) {
    return result.value.file;
  }
});

type File = typeof file.value;

const entity = computed(() =>
  (file.value?.relatedEntities ?? []).find(
    (entity) => entity.__typename == 'Publication'
  )
);

const getFileList = () => {
  if (entity.value && entity.value.__typename == 'Publication') {
    return entity.value.files.edges
      .filter((filteredFile) => {
        if (filteredFile.node.nature === 'SAMPLE') {
          return (
            filteredFile.node.nature === 'SAMPLE' ||
            filteredFile.node.nature === 'CONTENT'
          );
        }
        return file.value?.nature === filteredFile.node.nature;
      })
      .sort(
        (fileA, fileB) =>
          new Date(fileB?.node.created).getTime() -
          new Date(fileA?.node.created).getTime()
      );
  }
  return null;
};

const fileList: Ref<null | any[]> = computed(() => getFileList());

const canBeRepacked = (fileToRepack: File) => {
  const mostRecentFileByNature = fileList.value?.find(
    (file) => file.node.nature === fileToRepack?.nature
  );
  return (
    new Date(fileToRepack?.created) >=
    new Date(mostRecentFileByNature.node.created)
  );
};
</script>

<style></style>
