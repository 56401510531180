<template>
  <message-confirmation
    :isHidden="hideAlertMessage"
    titleInfoMessage="Creating new Subscription/s"
    :bodyInfoMessage="messageBodyInfoAlert"
    buttonConfirmMessage="Yes, create"
    buttonCancelMessage="Cancel"
    :showCancelButton="true"
    variant="alert"
    @answerUser="answerAlertMessage"
  ></message-confirmation>
  <message-confirmation
    :isHidden="hideInfoMessage"
    titleInfoMessage="Total number of created license/s"
    :bodyInfoMessage="messageBodyInfo"
    buttonConfirmMessage="Confirm"
    buttonCancelMessage="Cancel"
    @answerUser="answerInfoMessage"
  ></message-confirmation>
  <h2 class="page-title">Create periodical/s subscription/s from an order</h2>
  <div class="spacey-8 relative">
    <div class="section md:flex md:space-x-5">
      <div
        class="left-col-block md:flex-none md:w-1/3 text-center md:text-left"
      >
        <p class="text-gray-500 text-sm mb-5">
          Select the file's headers' language, the namespace and the order's
          file.
        </p>
      </div>
      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
      >
        <div class="px-5 pt-5 flex-grow w-full">
          <div class="sm:block mb-3">
            <select
              v-model="languageChoosen"
              class="w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              id="tk-inputs-default-select"
              @change="restartValues"
            >
              <option
                v-for="(value, index) in languagesAvailable"
                :key="value"
                :value="index"
              >
                {{ value }}
              </option>
            </select>
          </div>
        </div>
        <div class="px-5 flex-grow w-full">
          <div class="sm:block mb-3">
            <namespace-selector v-model="namespaceSelectedRef" />
          </div>
        </div>
        <div class="p-5 lg:p-6 flex-grow w-full">
          <div class="space-y-1">
            <div class="flex-grow w-full flex justify-end mb-4">
              <div class="grid justify-items-center">
                <file-upload
                  @uploaded="handleUploadedExcel"
                  accept=".xlsx"
                  btsize="m"
                  class="mt-3"
                  text="Select order's Excel file"
                />
              </div>
            </div>
            <div class="flex gap-x-2 justify-end">
              <div class="underline text-sm">
                <a
                  href="/periodicals/createPeriodicalSubscriptions_spanish_template.xlsx"
                  >[Download Spanish Template]</a
                >
              </div>
              <div class="underline text-sm">
                <a
                  href="/periodicals/createPeriodicalSubscriptions_template.xlsx"
                  >[Download English Template]</a
                >
              </div>
            </div>
            <div class="pt-4">
              <success-display :hide="!readingMetadataSuccess">
                {{ registersRead }}
              </success-display>
            </div>
            <div class="mt-4">
              <warning-display
                class="mt-3"
                :warning="warningsToDisplay"
                headerMessage="Warning/s found in the Excel file"
                :footerMessage="warningMessageFooter"
              ></warning-display>
            </div>
            <div class="mt-4">
              <error-display
                class="mt-3"
                :error="errorsToDisplay"
                headerMessage="Error/s found in the Excel file"
                :footerMessage="errorMessageFooter"
              ></error-display>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="min-w-full text-end" v-if="loading">Loading...</div>
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white mt-4"
        v-else-if="
          parseExcelResult?.success &&
          parseExcelResult?.entries &&
          parseExcelResult.entries.length > 0
        "
      >
        <div
          class="flex flex-col rounded-lg shadow-sm bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800"
        >
          <div class="py-4 px-5 bg-gray-50 dark:bg-gray-700/50">
            <h3 class="font-semibold mb-1">Order's identifier</h3>
            <h4 class="text-sm font-medium text-gray-500 dark:text-gray-400">
              {{ fileName }}
            </h4>
            <h3 class="font-semibold mb-1 mt-5">Buyer's type</h3>
            <h4 class="text-sm font-medium text-gray-500 dark:text-gray-400">
              {{ buyerType }}
            </h4>
            <h3 class="font-semibold mb-1 mt-5">Library</h3>
            <h4 class="text-sm font-medium text-gray-500 dark:text-gray-400">
              {{ namespaceSelectedRef?.name }}
            </h4>
            <div class="mt-4">
              <success-display :hide="!creatingSubscriptionsSuccess">
                {{ messageSuccessLicencesCreated }}
              </success-display>
            </div>
            <error-display
              class="mt-3"
              :error="serverErrors"
              headerMessage="Server errors."
            ></error-display>
          </div>
        </div>
        <Table
          center-header
          :headers="
            [0, 1, 2, 3, 4, 5, 6, 7].map((i) =>
              formatTableHeader(keysOriginalTemplateHeaders[i])
            )
          "
        >
          <tr v-for="entry of parseExcelResult.entries">
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              <router-link
                class="ml-3 hover:text-gray-200"
                :to="{
                  name: 'periodical',
                  params: {
                    id: `${
                      entry.orderSubscriptionsDataCandidate[
                        headers_values.origin[languageChoosen]
                      ]
                    }:${
                      entry.orderSubscriptionsDataCandidate[
                        headers_values.header_code[languageChoosen]
                      ]
                    }`,
                  },
                }"
                target="_blank"
                ><strong>{{
                  entry.orderSubscriptionsDataCandidate[
                    headers_values.header_code[languageChoosen]
                  ]
                }}</strong></router-link
              >
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                entry.orderSubscriptionsDataCandidate[
                  headers_values.origin[languageChoosen]
                ]
              }}
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                entry.orderSubscriptionsDataCandidate[
                  headers_values.header[languageChoosen]
                ]
              }}
            </td>

            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                entry.orderSubscriptionsDataCandidate[
                  headers_values.licenses_number[languageChoosen]
                ]
              }}
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                entry.orderSubscriptionsDataCandidate[
                  headers_values.concurrency[languageChoosen]
                ]
              }}
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                formatteDate(
                  entry.orderSubscriptionsDataCandidate[
                    headers_values.contract_from[languageChoosen]
                  ]
                )
              }}
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{
                formatteDate(
                  entry.orderSubscriptionsDataCandidate[
                    headers_values.contract_to[languageChoosen]
                  ]
                )
              }}
            </td>
          </tr>
        </Table>
      </div>
    </div>
    <div class="flex-grow w-full flex justify-end margin-space my-4">
      <div
        class="grid justify-items-center mr-5"
        v-if="
          parseExcelResult?.success &&
          parseExcelResult?.entries &&
          parseExcelResult.entries.length > 0
        "
      >
        <dm-button
          id="create-upload-publications"
          variant="danger"
          size="xL"
          @click.prevent="createSubscriptionsPerform()"
          >Create subscription/s</dm-button
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Ref, computed, inject, ref, watch } from 'vue';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { graphql } from '../../__generated__/gql';

import FileUpload from '../base/FileUpload.vue';
import MessageConfirmation from '../base/DialogMessage.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import WarningDisplay from '../WarningDisplay.vue';
import SuccessDisplay from '../SuccessDisplay.vue';
import DmButton from '../base/Button.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import Table from '../base/Table.vue';
import {
  CreatePeriodicalTemplateInput,
  PeriodicalOriginAndIdentifier,
} from '../../__generated__/graphql';

interface headers {
  [key: string]: any;
}

interface AttributeInput {
  key: string;
  value: string;
}

type PeriodicalOrigin = 'DM_SPAIN' | 'ODILO_SPAIN';

interface createPeriodicalTemplateInput {
  namespaceId: string | undefined;
  periodicalOrigin: PeriodicalOrigin;
  periodicalIdentifier: string;
  concurrency: number;
  count: number;
  startsAt: string;
  endsAt: string;
  customAttributes: AttributeInput[];
}

interface ExcelEntryInformation {
  message: string;
  entry?: number;
  identifier?: string;
  header?: string;
}
interface ExcelEntryError extends ExcelEntryInformation {}
interface ExcelEntryWarning extends ExcelEntryInformation {}
interface ParseExcelResult {
  entries?: any;
  errors?: (ExcelEntryError | string)[];
  warnings?: (ExcelEntryWarning | string)[];
  success: boolean;
}

const MAX_ERRORS_TO_DISPLAY: number = 10;
const MAX_WARNINGS_TO_DISPLAY: number = 10;
const MAX_LICENSES_BEFORE_WARNING: number = 10;

const SEARCH_PUBLICATIONS_BY_ORIGINS_AND_IDENTIFIERS_QUERY = graphql(`
  query periodicalsByOriginsAndIdentifiers(
    $identifiers: [PeriodicalOriginAndIdentifier!]!
  ) {
    periodicals: periodicalsByOriginsAndIdentifiers(identifiers: $identifiers) {
      origin
      identifier
    }
  }
`);

const CREATE_PERIODICAL_TEMPLATES_MUTATION = graphql(`
  mutation createPeriodicalTemplates(
    $input: [CreatePeriodicalTemplateInput!]!
  ) {
    createPeriodicalTemplates(input: $input) {
      numberOfCreatedPeriodicalTemplates
      success
    }
  }
`);

const headers_values: headers = {
  header: ['header', 'cabecera'],
  origin: ['origin', 'origen'],
  header_code: ['header_code', 'codigo_de_cabecera'],
  library: ['library', 'biblioteca'],
  licenses_number: ['number_of_licenses', 'numero_de_licencias'],
  concurrency: ['concurrency', 'concurrencia'],
  contract_from: ['contract_from', 'contrato_desde'],
  contract_to: ['contract_to', 'contrato_hasta'],
  buyer: ['buyer_type', 'tipo_de_comprador'],
};
const languagesAvailable: Ref<string[]> = ref(['english', 'spanish']);
const allOrigins = ['DM_SPAIN', 'ODILO_SPAIN'] as const;
const serverErrors: Ref<undefined | string> = ref(undefined);
const hideInfoMessage: Ref<boolean> = ref(true);
const messageBodyInfo: Ref<undefined | string> = ref(undefined);
const languageChoosen: Ref<number> = ref(1);
const namespaceSelectedRef: Ref<{ name: string; id: string } | null> =
  ref(null);
const creatingSubscriptionsSuccess: Ref<boolean> = ref(false);
const parseExcelResult: Ref<ParseExcelResult | null> = ref(null);
const readingMetadataSuccess: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(false);
const fileName: Ref<string> = ref('');
const keysOriginalTemplateHeaders: Ref<Array<string>> = ref([]);
const buyerType: Ref<string> = ref('');
const hideAlertMessage: Ref<boolean> = ref(true);

const messageSuccessLicencesCreated = computed(() => {
  return `${messageBodyInfo.value}. Identifier: ${fileName.value}. `;
});
const errorsToDisplay = computed(() => {
  if (
    parseExcelResult?.value?.errors &&
    parseExcelResult.value.errors.length > 0
  )
    return parseExcelResult.value.errors.slice(0, MAX_ERRORS_TO_DISPLAY);
});
const errorMessageFooter = computed(() => {
  if (
    parseExcelResult?.value?.errors &&
    parseExcelResult.value.errors.length > 0
  ) {
    let errorMessage = '';
    if (parseExcelResult.value.errors.length > MAX_ERRORS_TO_DISPLAY) {
      const nonDisplayedErrorsCount =
        parseExcelResult.value.errors.length - MAX_ERRORS_TO_DISPLAY;
      errorMessage = ` and ${nonDisplayedErrorsCount} more error${
        nonDisplayedErrorsCount > 1 ? `s` : ``
      }.`;
    }
    return (
      errorMessage +
      `Please correct ${
        parseExcelResult.value.errors.length == 1 ? `it` : `them`
      } and try it again`
    );
  }
});

const messageBodyInfoAlert = computed(() => {
  return `Are you sure you want to create ${
    parseExcelResult.value?.entries?.length
  } subscription/s and ${parseExcelResult.value?.entries?.reduce(
    (total: number, actualEntry: any): number =>
      Number(
        actualEntry.orderSubscriptionsDataCandidate[
          headers_values.licenses_number[languageChoosen.value]
        ]
      ) + total,
    0
  )} license/s?`;
});
const registersRead = computed(() => {
  if (
    parseExcelResult?.value?.entries &&
    parseExcelResult.value.entries.length > 0
  ) {
    const messageQuantity = parseExcelResult.value.entries.length;
    const wordQuantity = messageQuantity > 1 ? ` entries have` : ` entry has`;
    return `${messageQuantity} ${wordQuantity} been processed.`;
  }
});
const warningsToDisplay = computed(() => {
  if (
    parseExcelResult?.value?.warnings &&
    parseExcelResult.value.warnings.length > 0
  ) {
    return parseExcelResult.value.warnings.slice(0, MAX_WARNINGS_TO_DISPLAY);
  }
});
const warningMessageFooter = computed(() => {
  if (
    parseExcelResult?.value?.warnings &&
    parseExcelResult.value.warnings.length > 0
  ) {
    let errorMessage = '';
    if (parseExcelResult.value.warnings.length > MAX_WARNINGS_TO_DISPLAY) {
      const nonDisplayedWarningsCount =
        parseExcelResult.value.warnings.length - MAX_WARNINGS_TO_DISPLAY;
      errorMessage = ` and ${nonDisplayedWarningsCount} more warning${
        nonDisplayedWarningsCount > 1 ? `s` : ``
      }`;
    }
    return errorMessage;
  }
});
const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
const answerInfoMessage = async (answer: boolean) => {
  if (answer) {
    hideInfoMessage.value = true;
  }
};
const answerAlertMessage = async (answer: boolean) => {
  if (!answer) {
    hideAlertMessage.value = true;
  } else {
    const totalSubscriptionsInOrder: CreatePeriodicalTemplateInput[] =
      parseExcelResult.value?.entries.reduce(
        (
          input: createPeriodicalTemplateInput[],
          entry: any
        ): createPeriodicalTemplateInput[] => {
          input.push({
            namespaceId: namespaceSelectedRef.value?.id,
            periodicalOrigin:
              entry.orderSubscriptionsDataCandidate[
                headers_values.origin[languageChoosen.value]
              ],
            periodicalIdentifier:
              entry.orderSubscriptionsDataCandidate[
                headers_values.header_code[languageChoosen.value]
              ],
            concurrency:
              entry.orderSubscriptionsDataCandidate[
                headers_values.concurrency[languageChoosen.value]
              ],
            count:
              entry.orderSubscriptionsDataCandidate[
                headers_values.licenses_number[languageChoosen.value]
              ],
            startsAt:
              entry.orderSubscriptionsDataCandidate[
                headers_values.contract_from[languageChoosen.value]
              ],
            endsAt:
              entry.orderSubscriptionsDataCandidate[
                headers_values.contract_to[languageChoosen.value]
              ],
            customAttributes: [
              { key: 'buyer_type', value: buyerType.value },
              { key: 'order_identifier', value: fileName.value },
            ],
          });
          return input;
        },
        []
      );
    const result = await createPeriodicalsTemplate(totalSubscriptionsInOrder);
    if (result.data.createPeriodicalTemplates.success) {
      readingMetadataSuccess.value = false;
      hideAlertMessage.value = true;
      const totalSubscriptions =
        result.data.createPeriodicalTemplates
          .numberOfCreatedPeriodicalTemplates;
      messageBodyInfo.value = `${
        totalSubscriptions > 1
          ? `Have been created ${totalSubscriptions} subscriptions`
          : `Has been created 1 subscription`
      }`;
      creatingSubscriptionsSuccess.value = true;
      hideInfoMessage.value = false;
    } else {
      serverErrors.value = 'Try it again later.';
    }
    hideAlertMessage.value = true;
  }
};
const handleUploadedExcel = async (file: any) => {
  creatingSubscriptionsSuccess.value = false;
  parseExcelResult.value = null;
  readingMetadataSuccess.value = false;
  loading.value = true;
  fileName.value = file.name.substring(0, file.name.lastIndexOf('.'));
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, {
    cellDates: true,
    dateNF: 'dd"/"mm"/"yyyy',
  });
  const excelRawData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]],
    { header: 1, blankrows: false, raw: false }
  );
  let temporalResult: ParseExcelResult = await parseExcel(excelRawData);
  if (temporalResult.success) {
    const MAX_SUBSCRIPTIONS_TYPES_ALLOWED = 200;
    if (temporalResult.entries.length > MAX_SUBSCRIPTIONS_TYPES_ALLOWED) {
      temporalResult.success = false;
      temporalResult.errors?.push({
        message: `The maximum number of subscription types allowed to be created at the same time is ${MAX_SUBSCRIPTIONS_TYPES_ALLOWED} but you are requesting to create ${temporalResult.entries.length} sets of subscriptions.`,
      });
    } else {
      // Check periodicals
      const periodicalsIdentifiers = temporalResult.entries.map(
        (item: { orderSubscriptionsDataCandidate: { [x: string]: any } }) => {
          return {
            origin:
              item.orderSubscriptionsDataCandidate[
                headers_values.origin[languageChoosen.value]
              ],
            identifier:
              item.orderSubscriptionsDataCandidate[
                headers_values.header_code[languageChoosen.value]
              ],
          };
        }
      );
      const result = await getPeriodicals(periodicalsIdentifiers);
      if (result.errors) {
      } else {
        if (Array.isArray(result.data.periodicals)) {
          const dictionaryResultPeriodicals = Object.assign(
            {},
            ...result.data.periodicals.map(
              (periodical: { identifier: string; origin: string }) => ({
                [`${periodical.identifier}:${periodical.origin}`]: periodical,
              })
            )
          );
          temporalResult.entries.forEach(
            (periodical: { orderSubscriptionsDataCandidate: any }) => {
              const indentifierComposedByOriginAndIdentfier: string = `${
                periodical.orderSubscriptionsDataCandidate[
                  headers_values.header_code[languageChoosen.value]
                ]
              }:${
                periodical.orderSubscriptionsDataCandidate[
                  headers_values.origin[languageChoosen.value]
                ]
              }`;
              if (
                !(
                  indentifierComposedByOriginAndIdentfier in
                  dictionaryResultPeriodicals
                )
              ) {
                temporalResult.success = false;
                temporalResult.errors?.push({
                  identifier: indentifierComposedByOriginAndIdentfier,
                  message: `The periodical with origin ${
                    periodical.orderSubscriptionsDataCandidate[
                      headers_values.origin[languageChoosen.value]
                    ]
                  } and identifier ${
                    periodical.orderSubscriptionsDataCandidate[
                      headers_values.header_code[languageChoosen.value]
                    ]
                  } doesn't exist`,
                });
              }
            }
          );
        }
      }
    }
  }
  loading.value = false;
  parseExcelResult.value = temporalResult;
  readingMetadataSuccess.value = temporalResult.success;
};
async function parseExcel(entries: any): Promise<ParseExcelResult> {
  const rx = /\s*([^\s\(\)][^\(\)]*[^\s\(\)])\s*(\(.*\))?\s*/;
  const headers = entries[0];
  const data = entries.slice(1);
  const indexes: any = [];
  const parsingHeaderErrors: string[] = [];
  const originalTemplateHeaders: headers = {
    [headers_values.header_code[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      if (typeof value != 'string') {
        return {
          error: `Invalid header code format, value: '${value}'`,
        };
      } else {
        value = value.replace(/\s/g, '').trim();
        // Usually length 4. Ex: 0000
        return value.length > 3
          ? { value }
          : {
              warning: `The value shown in the Excel entry: ${value} has ${value.length} as long, usually header codes have 4`,
            };
      }
    },
    [headers_values.origin[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      return allOrigins.includes(value.toUpperCase())
        ? { value }
        : {
            error: `The value shown in the Excel entry: ${value} does not match any of the origins: ${allOrigins.toString()}`,
          };
    },
    [headers_values.header[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { value: null };
      }

      if (typeof value != 'string') {
        return {
          error: `Empty field`,
        };
      }
      return { value };
    },
    [headers_values.library[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      const formattedValue = removeAccents(value)
        .replace(/\s/g, '')
        .toLowerCase();
      return formattedValue == namespaceSelectedRef.value?.name
        ? { value }
        : {
            warning: `The value shown in the Excel entry: ${value} does not match the value formerly selected: ${namespaceSelectedRef.value?.name}`,
          };
    },
    [headers_values.licenses_number[languageChoosen.value]]: function (
      value: any
    ) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      return !isNaN(value)
        ? { value: Number(value) }
        : {
            error: `Unexpected field data value (expected: numerical):${value}`,
          };
    },
    [headers_values.concurrency[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      return !isNaN(value)
        ? { value: Number(value) }
        : {
            error: `Unexpected field data value (expected: numerical):${value}`,
          };
    },
    [headers_values.contract_from[languageChoosen.value]]: function (
      value: any
    ) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      if (typeof value == 'string') {
        //necessary to work fine validation
        dayjs.extend(customParseFormat);
        const parsedDate = dayjs(value, 'DD-MM-YYYY', true);
        if (!parsedDate.isValid()) {
          return {
            error: `Invalid date format, value:'${value}'`,
          };
        } else {
          return { value: parsedDate.format('YYYY-MM-DD') };
        }
      } else if (value instanceof Date) {
        return { value };
      } else {
        return {
          error: `Unexpected field data type (expected Date or string).`,
        };
      }
    },
    [headers_values.contract_to[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      if (typeof value == 'string') {
        //necessary to work fine validation
        dayjs.extend(customParseFormat);
        const parsedDate = dayjs(value, 'DD-MM-YYYY', true);
        if (!parsedDate.isValid()) {
          return {
            error: `Invalid date format, value:'${value}'`,
          };
        } else {
          return { value: parsedDate.format('YYYY-MM-DD') };
        }
      } else if (value instanceof Date) {
        return { value };
      } else {
        return {
          error: `Unexpected field data type (expected Date or string).`,
        };
      }
    },
    [headers_values.buyer[languageChoosen.value]]: function (value: any) {
      if (value == null || value.trim().length === 0) {
        return { error: `Empty field` };
      }
      if (typeof value != 'string') {
        return {
          error: 'Unexpected field data type (expected Date or string)',
        };
      }

      buyerType.value = value;
      return { value };
    },
  };

  keysOriginalTemplateHeaders.value = Object.keys(originalTemplateHeaders);
  if (headers) {
    if (headers.length != Object.keys(headers_values).length) {
      return {
        success: false,
        errors: [`The headers are not the right format`],
      };
    }
    for (let i = 0; i < headers.length; i++) {
      const headerExtraction = rx.exec(headers[i]);
      if (!headerExtraction) {
        parsingHeaderErrors.push(
          `Unexpected header string, value:${headers[i]}`
        );
      } else {
        const header: string = headerExtraction[0]
          .toLowerCase()
          .replace(/\s/g, '_')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        if (
          !keysOriginalTemplateHeaders.value.find(
            (element) => element === header
          )
        ) {
          parsingHeaderErrors.push(`Unknown header, value: ${header}`);
        } else {
          indexes[i] = header;
        }
      }
    }
    if (parsingHeaderErrors.length > 0) {
      return { errors: parsingHeaderErrors, success: false };
    }
    const parsingEntryErrors: ExcelEntryError[] = [];
    const parsingEntryWarnings: ExcelEntryWarning[] = [];
    const subscriptionsList: any[] = [];
    if (data.length == 0) {
      return {
        success: false,
        errors: ['No data in file'],
      };
    }
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      if (entry.length > headers.length) {
        parsingEntryErrors.push({
          message: 'No data in file',
          entry: i,
        });
      } else {
        //each register
        const orderSubscriptionsDataCandidate: { [key: string]: any } = {};
        for (let j = 0; j < headers.length; j++) {
          const validation = await originalTemplateHeaders[indexes[j]](
            entry[j]
          );
          if (validation.error != null) {
            parsingEntryErrors.push({
              message: validation.error,
              entry: i + 1,
              header: indexes[j],
            });
          }
          if (validation.warning != null) {
            parsingEntryWarnings.push({
              message: validation.warning,
              entry: i + 1,
              header: indexes[j],
            });
          }
          orderSubscriptionsDataCandidate[indexes[j]] = validation.value;
        }
        subscriptionsList.push({
          orderSubscriptionsDataCandidate,
        });
      }
    }
    const totalLicensesInOrder = subscriptionsList.reduce(
      (totalLicenses, element): number =>
        totalLicenses +
        element.orderSubscriptionsDataCandidate[
          headers_values.licenses_number[languageChoosen.value]
        ],
      0
    );
    if (totalLicensesInOrder > MAX_LICENSES_BEFORE_WARNING) {
      parsingEntryWarnings.unshift({
        message: `You will create ${subscriptionsList.length} subscription/s and ${totalLicensesInOrder} licenses in total`,
      });
    }
    return {
      success: parsingEntryErrors.length == 0,
      entries: subscriptionsList,
      errors: parsingEntryErrors,
      warnings: parsingEntryWarnings,
    };
  }
  return {
    success: false,
    errors: ['The header fields are empty'],
  };
}
async function getPeriodicals(
  identifiers: PeriodicalOriginAndIdentifier[]
): Promise<any> {
  return apolloClient.query({
    query: SEARCH_PUBLICATIONS_BY_ORIGINS_AND_IDENTIFIERS_QUERY,
    variables: { identifiers },
    fetchPolicy: 'network-only',
  });
}
async function createPeriodicalsTemplate(
  input: CreatePeriodicalTemplateInput[]
): Promise<any> {
  return apolloClient.mutate({
    mutation: CREATE_PERIODICAL_TEMPLATES_MUTATION,
    variables: { input },
  });
}
function formatTableHeader(parameter: string) {
  return parameter.replace(/_/g, ' ');
}
function createSubscriptionsPerform() {
  hideAlertMessage.value = false;
}
function formatteDate(date: string): string {
  dayjs.extend(customParseFormat);
  return dayjs(date).format('DD/MM/YYYY');
}
// change namespace
watch(namespaceSelectedRef, async (newValue) => {
  if (newValue) {
    restartValues();
  }
});
function restartValues() {
  parseExcelResult.value = null;
  readingMetadataSuccess.value = false;
}
</script>
