<template>
  <h2 class="page-title">
    Shares for {{ result?.publication?.isbn ?? 'Undefined ISBN' }}:
    {{ result?.publication?.metadata?.title ?? 'Untitled' }}
  </h2>

  <Table
    :headers="[
      'Covert',
      'From',
      'To',
      'URL (Colibrio)',
      'URL (New Web Reader)',
      '',
    ]"
    :hasNextPage="result?.shares?.pageInfo?.hasNextPage"
    :hasPreviousPage="result?.shares?.pageInfo?.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? nextPage() : previousPage())"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="result"
      v-for="share of result.shares.edges"
      :key="share.node.id"
      class="border-b border-gray-200"
    >
      <td class="p-3">
        {{ share.node.covert }}
      </td>
      <td class="p-3 text-gray-500">
        <dm-date :date="share.node.from" />
      </td>
      <td class="p-3 text-gray-500">
        <dm-date v-if="share.node.to" :date="share.node.to" />
        <span v-else>Never</span>
      </td>
      <td
        class="p-3 max-w-sm truncated text-xs text-ellipsis overflow-hidden text-gray-500"
      >
        <a :href="share.node.webReaderUrl" target="_blank">{{
          share.node.webReaderUrl
        }}</a>
      </td>

      <td
        class="p-3 max-w-sm truncated text-xs text-ellipsis overflow-hidden text-gray-500"
      >
        <a :href="share.node.newWebReaderUrl" target="_blank">{{
          share.node.newWebReaderUrl
        }}</a>
      </td>

      <td class="p-3">
        <dm-button
          variant="danger"
          size="xs"
          @click.prevent="deleteShare(share.node.id)"
          v-if="canWriteResourceTypeOnAtLeastOneNamespace('shares')"
          >delete</dm-button
        >
      </td>
    </tr>
  </Table>

  <div class="mt-3" v-if="canWriteResourceTypeOnAtLeastOneNamespace('shares')">
    <dm-button>
      <router-link
        :to="{
          name: 'new-publication-share',
          params: { id: publicationId },
        }"
      >
        Create new share
      </router-link>
    </dm-button>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import { useQuery, DefaultApolloClient } from '@vue/apollo-composable';
import { computed, Ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import { ApolloClient } from '@apollo/client/core';
import { NormalizedCacheObject } from '@apollo/client/cache';

import { graphql } from '../../__generated__/gql';

import { useAuth } from '../../auth';
import { GetSharesQueryVariables } from '../../__generated__/graphql';
import Table from '../base/Table.vue';

const perPage = 25;
const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const route = useRoute();
const publicationId = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();

const variables: Ref<GetSharesQueryVariables> = computed(() => ({
  publicationId: publicationId.value,
  first: perPage,
}));

const { result, loading, error, fetchMore, refetch } = useQuery(
  graphql(`
    query getShares(
      $publicationId: ID!
      $first: Int
      $last: Int
      $before: String
      $after: String
    ) {
      publication(id: $publicationId) {
        id
        isbn
        metadata {
          title
        }
      }

      shares(
        filterBy: { publicationId: $publicationId }
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            covert
            from
            to
            webReaderUrl
            newWebReaderUrl
          }
        }
      }
    }
  `),
  variables,
  {
    fetchPolicy: 'cache-and-network',
  }
);

async function deleteShare(id: string): Promise<void> {
  await apolloClient.mutate({
    mutation: graphql(`
      mutation deleteShare($id: ID!) {
        deleteShare(input: { id: $id }) {
          success
        }
      }
    `),
    variables: { id },
  });
  refetch();
}

function nextPage() {
  fetchMore({
    variables: {
      first: perPage,
      last: null,
      after: result.value?.shares?.pageInfo?.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function previousPage() {
  fetchMore({
    variables: {
      last: perPage,
      first: null,
      before: result.value?.shares?.pageInfo?.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
</script>
