<template>
  <div v-if="!$props.dropZone">
    <dm-button :size="btsize" :loading="loading" @click="triggerFileInput">
      {{ text }}
    </dm-button>
    <input
      multiple="false"
      ref="fileElement"
      type="file"
      @change="handleFileUpload"
      class="hidden"
      :accept="accept"
    />
  </div>
  <div
    v-else-if="$props.dropZone"
    ref="dropZoneRef"
    class="dropzone"
    @dragenter.prevent="toggleActiveDropzone"
    @dragleave.prevent="toggleActiveDropzone"
    @dragover.prevent
    :class="{ 'active-dropzone': isOverDropZone }"
    @drop.prevent="toggleActiveDropzone"
    @click="triggerFileInput"
    @drop="onDrop"
    title="Click to select a file"
  >
    <input
      multiple="false"
      ref="fileElement"
      type="file"
      @change="handleFileUpload"
      class="hidden"
      :accept="accept"
    />
    <span v-if="!loading" class="contents">
      <svg
        class="hi-mini hi-arrow-down-tray inline-block w-10 h-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z"
        />
        <path
          d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z"
        />
      </svg>
      <span v-if="!isOverDropZone && $props.dropZoneText" class="text-center">
        <span v-html="props.dropZoneText"></span>
      </span>
    </span>

    <div v-if="loading" class="text-center w-3/4">
      <span class="loading-ellipsis">
        {{ validationLoading ? 'Validating' : 'Uploading' }}
      </span>
      <div
        v-if="progressBar"
        class="flex h-4 mt-2 w-full items-center overflow-hidden rounded-full bg-indigo-100 dark:bg-indigo-950"
      >
        <div
          role="progressbar"
          :aria-valuenow="uploadTracker"
          aria-valuemin="0"
          aria-valuemax="100"
          class="flex items-center justify-center self-stretch rounded-full bg-indigo-600 text-xs font-medium text-white transition-all duration-500 ease-out"
          :style="{ width: Math.max(uploadTracker, 10) + '%' }"
        >
          {{ uploadTracker }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { useDropZone } from '@vueuse/core';
import { useToast } from 'vue-toastification';
import axios from 'axios';

import { File } from 'buffer';

const toast = useToast();

type UrlFetchFunction = (originalFile: {
  name: string;
  type: string;
}) => Promise<String>;

const emits = defineEmits<{
  (e: 'uploaded', file: File): void;
  (e: 'failed', error: string): void;
}>();
const props = defineProps({
  dropZone: { type: Boolean, required: false },
  dropZoneText: { type: String, required: false },
  fetchUploadUrl: {
    // if defined, upload the file (PUT) to the url returned by this function instead of calling the handler function.
    type: Function as PropType<UrlFetchFunction>,
    required: false,
  },
  accept: {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
    type: String,
    required: false,
    default: '*',
  },
  text: {
    type: String,
    required: false,
    default: 'Select File',
  },
  btsize: {
    type: String,
    required: false,
    default: 'm',
  },
  extraHeaders: {
    // Extra HTTP headers to send with the upload request.
    type: Object as PropType<{ [headerName: string]: string }>,
    required: false,
  },
  validation: {
    type: Function as PropType<(file: File) => Promise<string | null>>,
    required: false,
  },
  progressBar: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = ref(false);
const validationLoading = ref(false);
const fileElement = ref();
const uploadTracker = ref(0);

const dropZoneRef = ref<HTMLDivElement>();
const { isOverDropZone } = useDropZone(dropZoneRef);

const onDrop = (e: DragEvent) => {
  fileElement.value = e.dataTransfer;
  handleFileUpload();
};

const toggleActiveDropzone = () => {
  isOverDropZone.value = !isOverDropZone.value;
};

const handleFileUpload = async () => {
  if (fileElement.value) {
    let selectedFile = fileElement.value['files'][0] as File;
    fileElement.value.value = null;

    if (props.fetchUploadUrl != null) {
      loading.value = true;

      if (props.validation != null) {
        validationLoading.value = true;
        const warning = await props.validation(selectedFile);
        if (warning != null) {
          toast.warning(warning);
        }
        validationLoading.value = false;
      }

      // upload to server.
      const uploadUrl = await props.fetchUploadUrl(selectedFile);

      try {
        await axios.put(uploadUrl.toString(), selectedFile, {
          headers: props.extraHeaders,
          onUploadProgress(progressEvent) {
            if (progressEvent && progressEvent.total) {
              uploadTracker.value = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }
          },
        });
        emits('uploaded', selectedFile);
      } catch (error: any) {
        emits('failed', 'Could not upload file.');
      } finally {
        loading.value = false;
        uploadTracker.value = 0;
      }
    } else {
      emits('uploaded', selectedFile);
    }
  }
};

const triggerFileInput = () => {
  if (fileElement.value) {
    (fileElement.value as any).click();
  }
};
</script>

<style scoped>
.dropzone {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #6b7280;
  background-color: #f3f4f6;
  transition: 0.3s ease all;
  color: #6b7280;
  border-radius: 8px;
}

.dropzone:hover {
  cursor: cell;
}

.active-dropzone {
  color: #4338c9;
  border: 2px solid #4338c9;
  background-color: #f3f4f6;
}
</style>
