<template>
  <div v-if="loading">Loading...</div>
  <template v-else-if="!publication">
    <error-display v-if="error" headerMessage="Error" :error="error" />
    <warning-display
      v-else
      headerMessage="Warning"
      warning="Publication Not Found"
    />
  </template>
  <template v-else-if="publication">
    <h2 class="page-title">
      <div class="text-gray-500">{{ publication.namespace?.name }}</div>
      <div class="flex justify-between">
        <div>
          {{ publication.isbn }}:
          {{ publication.metadata?.title || 'Untitled' }}
          <div
            v-if="publication.metadata?.nature"
            class="font-semibold inline-flex px-3 py-1 leading-4 items-center space-x-3 text-sm rounded m-2"
            :class="
              colorAccordingToPublicationNature(publication.metadata?.nature)
            "
          >
            <span>
              {{ splitPublicationNature(publication.metadata?.nature) }}
            </span>
          </div>
        </div>
        <!-- Dropdowns: Simple -->
        <div v-if="canOpenInWebReader()">
          <!-- Dropdown Container -->
          <div
            class="relative inline-block group"
            :class="showWebreaderDropdown ? 'z-1' : ''"
          >
            <!-- Dropdown Toggle Button -->
            <button
              type="button"
              @click="showWebreaderDropdown = !showWebreaderDropdown"
              class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-2 py-1 leading-5 text-sm border-blue-700 bg-blue-700 text-white"
              id="tk-dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              :disabled="publication?.metadata === null"
              :class="
                publication?.metadata === null
                  ? 'opacity-50 cursor-not-allowed'
                  : ' hover:text-white hover:bg-blue-600 hover:border-blue-600 focus:ring focus:ring-blue-400 focus:ring-opacity-50 active:bg-blue-700 active:border-blue-700 dark:focus:ring-blue-400 dark:focus:ring-opacity-90'
              "
            >
              <span>Web Reader</span>
              <svg
                class="hi-mini hi-chevron-down inline-block w-4 h-4 opacity-50"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div
              v-if="publication?.metadata === null"
              class="duration-50 invisible absolute bottom-full left-1/2 z-1 -ml-20 flex w-40 origin-bottom translate-y-2 scale-75 flex-col items-center justify-center pb-0.5 opacity-75 transition ease-out will-change-auto group-hover:visible group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100"
            >
              <div
                class="flex-none rounded-lg bg-indigo-600 px-2.5 py-2 text-center text-xs font-semibold text-white dark:bg-indigo-800"
              >
                The web reader requires metadata.
              </div>
              <div
                class="h-0 w-0 flex-none border-l-4 border-r-4 border-t-4 border-l-transparent border-r-transparent border-t-indigo-600 dark:border-t-indigo-800"
                aria-hidden="true"
              ></div>
            </div>
            <Transition name="webreader-menu-fade">
              <div
                v-if="showWebreaderDropdown"
                role="menu"
                aria-labelledby="tk-dropdown"
                class="absolute right-0 origin-top-right mt-2 w-72 shadow-xl rounded-lg dark:shadow-gray-900"
              >
                <div
                  class="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700"
                >
                  <div class="p-2.5 space-y-1">
                    <button
                      @click="openInWebReader"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <book-open-icon class="w-5 h-5 inline-block" />
                      <span>Full in Colibrio</span>
                    </button>

                    <button
                      @click="openInNewWebReader"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <book-open-icon class="w-5 h-5 inline-block" />
                      <span>Full in Demarque Reader</span>
                    </button>
                    <button
                      @click="openSampleInNewWebReader"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <book-open-icon class="w-5 h-5 inline-block" />

                      <span>Sample in Demarque Reader</span>
                    </button>
                  </div>
                  <div class="p-2.5 space-y-1">
                    <button
                      @click="openInNewWebReaderPreProd"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <arrow-up-icon class="w-5 h-5 inline-block" />

                      <span>Full (pre-prod)</span>
                    </button>
                    <button
                      @click="openSampleInNewWebReaderPreProd"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <arrow-up-icon class="w-5 h-5 inline-block" />
                      <span>Sample (pre-prod)</span>
                    </button>
                  </div>
                  <div class="p-2.5 space-y-1">
                    <button
                      @click="openInNewWebReaderStaging"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <beaker-icon class="w-5 h-5 inline-block" />

                      <span>Full (staging)</span>
                    </button>
                    <button
                      @click="openSampleInNewWebReaderStaging"
                      role="menuitem"
                      class="group w-full text-sm font-medium flex items-center justify-left space-x-2 px-2.5 py-2 rounded-lg text-gray-700 border border-transparent hover:text-blue-800 hover:bg-blue-50 active:border-blue-100 dark:text-gray-300 dark:hover:text-blue-100 dark:hover:bg-blue-500 dark:hover:bg-opacity-20 dark:active:border-blue-500 dark:active:border-opacity-25"
                    >
                      <beaker-icon class="w-5 h-5 inline-block" />
                      <span>Sample (staging)</span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition>
            <!-- END Dropdown -->
          </div>
          <!-- Dropdown Container -->
        </div>
        <!-- END Dropdowns: Simple -->
      </div>
    </h2>

    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-6 w-full">
      <!-- Card Cover -->
      <div
        class="flex flex-col col-span-1 rounded shadow-sm bg-white py-4 self-center items-center justify-center h-full"
      >
        <div v-if="coverSrc">
          <div class="container flex z-0">
            <img
              :src="coverSrc"
              alt="Cover preview"
              class="shadow-slate-500 shadow-lg rounded-sm"
            />
            <div class="overlay">
              <file-upload
                class="cover-btn"
                @uploaded="handleUploadedCover"
                @failed="handleFailedUpload"
                :dropZone="true"
                dropZoneText="Drop a cover or click here to upload"
                accept=".jpg, .jpeg, .png"
                :extraHeaders="{
                  'x-goog-meta-force-overwrite': 'true',
                }"
                :fetchUploadUrl="getUrlFetcher('COVER', true)"
                :validation="fileUploadValidation('COVER')"
                :progressBar="true"
              />
            </div>
          </div>
        </div>

        <div v-else class="w-3/4 custom-cover-height self-center">
          <file-upload
            @uploaded="handleUploadedCover"
            @failed="handleFailedUpload"
            :dropZone="true"
            dropZoneText="Drop a cover or click here to upload"
            accept=".jpg, .jpeg, .png"
            :extraHeaders="{
              'x-goog-meta-force-overwrite': 'true',
            }"
            :fetchUploadUrl="getUrlFetcher('COVER', true)"
            :validation="fileUploadValidation('COVER')"
            :progressBar="true"
          />
        </div>
      </div>
      <!-- END Card Cover -->

      <!-- Upper right part -->
      <div class="flex flex-col gap-3 lg:col-span-2">
        <!-- Card Fulfillment -->
        <ul class="rounded bg-white divide-y divide-gray-200">
          <li
            v-for="potential of potentialFulfillments"
            v-bind:key="potential.key"
            class="p-4 flex justify-between items-center"
          >
            <span class="font-semibold text-sm mr-1">
              {{ potential.label }}
            </span>
            <div class="group relative inline-block">
              <span
                :class="
                  availabilityCheckForfulfillmentMethods(potential.key)
                    ? 'text-green-500 cursor-pointer'
                    : 'text-red-500'
                "
                class="text-sm"
                @click="
                  showFile(
                    fileList?.fulfillmentMethodsFiles(potential.key).value
                      ?.id ?? null
                  )
                "
              >
                {{
                  availabilityCheckForfulfillmentMethods(potential.key)
                    ? 'Available'
                    : 'Unavailable'
                }}
              </span>
              <span
                v-if="availabilityCheckForfulfillmentMethods(potential.key)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="grey"
                  class="h-4 ml-1 hi-micro hi-information-circle inline-block size-1"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                  />
                </svg>
              </span>
              <div>
                <div
                  v-if="availabilityCheckForfulfillmentMethods(potential.key)"
                  class="duration-50 invisible absolute bottom-0 right-full top-0 z-1 flex w-48 origin-right translate-x-2 scale-75 items-center justify-end pr-0.5 opacity-75 transition ease-out will-change-auto group-hover:visible group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100"
                >
                  <div
                    class="rounded-lg border bg-white text-left text-sm dark:border-gray-700 dark:bg-gray-800"
                  >
                    <p
                      class="p-3 text-gray-500 dark:text-gray-400"
                      v-if="
                        fileList?.fulfillmentMethodsFiles(potential.key).value
                      "
                    >
                      {{
                        fileList?.fulfillmentMethodsFiles(potential.key).value
                          ?.availability
                      }}
                    </p>
                  </div>
                  <div
                    class="relative z-1 -ml-px h-0 w-0 flex-none border-b-8 border-l-8 border-t-8 border-b-transparent border-l-white border-t-transparent dark:border-l-gray-800"
                    aria-hidden="true"
                  ></div>
                  <div
                    class="relative z-0 -ml-[7px] h-0 w-0 flex-none border-b-8 border-l-8 border-t-8 border-b-transparent border-l-gray-300 border-t-transparent dark:border-l-gray-600"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- End Card Fulfillment -->

        <!-- Card DropFiles -->
        <div
          class="p-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 w-full lg:inline-grid inline-flex rounded bg-white"
        >
          <!-- Card Body: Simple Widget with Action -->
          <div class="flex-grow w-full flex justify-center">
            <div class="w-3/4 lg:h-52 shadow-sm rounded-lg shadow-slate-500">
              <file-upload
                :dropZone="true"
                dropZoneText="Drop your <b>content</b> here"
                @uploaded="handleUploadedContent"
                @failed="handleFailedUpload"
                accept=".pdf, .epub, .zip"
                text="Set Content"
                :extraHeaders="{
                  'x-goog-meta-force-overwrite': 'true',
                }"
                :fetchUploadUrl="getUrlFetcher('CONTENT', true)"
                :validation="fileUploadValidation('CONTENT')"
                :progressBar="true"
              />
            </div>
          </div>
          <!-- Card Body: Simple Widget with Action -->
          <div class="flex-grow w-full flex justify-center">
            <div class="w-3/4 lg:h-52 shadow-sm rounded-lg shadow-slate-500">
              <file-upload
                :dropZone="true"
                dropZoneText="Drop your <b>sample</b> here"
                @uploaded="handleUploadedSample"
                @failed="handleFailedUpload"
                accept=".pdf, .epub"
                :extraHeaders="{
                  'x-goog-meta-force-overwrite': 'true',
                }"
                :fetchUploadUrl="getUrlFetcher('SAMPLE', true)"
                :validation="fileUploadValidation('SAMPLE')"
                :progressBar="true"
              />
            </div>
          </div>
        </div>
        <!-- END Card DropFiles -->

        <!-- Card License -->
        <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
          <!-- Card Body: Simple Widget with Action -->
          <div
            class="grid-cols-5 flex-grow w-full h-fit items-center inline-grid p-5 lg:p-6 self-center justify-center flex-wrap"
          >
            <div class="col-span-1 flex w-full">
              <dl class="vertical-line p-2">
                <dt class="text-2xl font-semibold">
                  {{ publication.licenses.totalCount }}
                </dt>
                <dd
                  class="uppercase font-medium text-sm text-gray-500 tracking-wider"
                >
                  Licenses
                </dd>
              </dl>
            </div>

            <div class="col-span-4 inline-flex p-2 justify-center flex-wrap">
              <router-link
                :to="
                  hasLicensesReadRight
                    ? {
                        name: 'licenses',
                        query: { entity: publication.id },
                      }
                    : ''
                "
                class="simple-btn"
                :class="hasLicensesReadRight ? 'active-btn' : 'disabled-btn'"
              >
                <svg
                  class="bi bi-search inline-block w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="gray"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
                Explore licenses
              </router-link>

              <!-- Card Body: Simple Widget with Action -->
              <dm-button
                @click="forceLicensesUpdate"
                :loading="forceLicensesUpdateLoading"
                class="simple-btn"
                :class="hasLicensesWriteRight ? 'active-btn' : 'disabled-btn'"
                variant="simple"
                :enabled="hasLicensesWriteRight"
              >
                <svg
                  class="bi bi-gear inline-block w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="gray"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <path
                    d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
                  />
                  <path
                    d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
                  />
                </svg>
                Force licenses update
              </dm-button>

              <div>
                <div class="flex-grow w-full flex justify-between items-center">
                  <router-link
                    :to="
                      hasLicensesWriteRight
                        ? {
                            name: 'new_license',
                            params: { id: publication.id },
                          }
                        : ''
                    "
                    class="simple-btn"
                    :class="
                      hasLicensesWriteRight ? 'active-btn' : 'disabled-btn'
                    "
                  >
                    <svg
                      class="bi bi-plus-square inline-block w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="gray"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                    >
                      <path
                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                      />
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                    <span> Add a License</span>
                  </router-link>
                </div>
              </div>

              <!-- Action Link -->
              <router-link
                :to="{
                  name: 'publication-shares',
                  params: { id: publication.id },
                }"
                class="simple-btn active-btn"
              >
                <svg
                  class="bi bi-search inline-block w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="gray"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
                Explore Shares
              </router-link>
              <!-- END Action Link -->
            </div>
          </div>
        </div>
        <!-- END Card License -->
      </div>
      <!-- END Upper right part -->

      <!-- Card Summary -->
      <div class="col-span-1 lg:col-span-3 w-full justify-around">
        <div class="w-full inline-grid rounded bg-white text-justify p-5">
          <div class="pt-2 pl-2 flex flex-row justify-between flex-wrap">
            <div>
              <p class="font-semibold text-xl text-gray-700">
                {{ publication.metadata?.title || 'Untitled' }}
              </p>
              <p class="font-semibold mb-1 text-indigo-500">
                {{ publication.metadata?.author?.name }}
              </p>
            </div>

            <div v-if="hasPublicationWriteRigth">
              <p class="font-semibold text-indigo-500 float-right mb-1">
                <template v-if="publication.metadata != null">
                  <router-link
                    :to="{
                      name: 'publications-metadata-edit',
                      query: { entity: publication.id },
                    }"
                  >
                    <dm-button variant="simple">
                      <svg
                        class="bi bi-pencil inline-block w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="gray"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                        />
                      </svg>
                      Edit Metadata
                    </dm-button>
                  </router-link>
                </template>
                <template v-else>
                  <router-link
                    v-if="publication.isbn && publication.namespace"
                    :to="{
                      name: 'new_publication_metadata',
                      query: {
                        isbn: publication.isbn,
                        namespace_id: publication.namespace.id,
                        namespace: publication.namespace.name,
                      },
                    }"
                  >
                    <button variant="simple">
                      <svg
                        class="bi bi-plus-square inline-block w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="gray"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                      >
                        <path
                          d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                        />
                        <path
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                        />
                      </svg>
                      Create Metadata
                    </button>
                  </router-link>
                </template>
              </p>
            </div>
          </div>

          <p v-if="summary" class="summary">
            {{ summaryRef }}
          </p>
          <p v-else class="text-gray-500 p-2">
            No summary has been added for this publication.
          </p>
          <button
            class="text-center pt-4 font-black text-indigo-500"
            v-if="
              publication?.metadata?.summary &&
              publication?.metadata?.summary.length > 500
            "
            :title="'See More'"
            @click="getSummary()"
          >
            <svg
              v-if="!toggleSummary"
              class="bi bi-chevron-down inline-block w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <svg
              v-else
              class="bi bi-chevron-up inline-block w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              />
            </svg>
          </button>
        </div>
      </div>
      <!--END Card Summary-->

      <!-- Card Files -->
      <div class="col-span-1 lg:col-span-3 w-full justify-around">
        <div class="rounded shadow-sm bg-white overflow-hidden p-5 w-full">
          <file-list
            :entityId="publication.id"
            :extractedFromFileId="
              publication.extractedMetadata?.extractedFromFileId
            "
            @no-files="checkIfFilesCardEmpty = false"
            ref="fileList"
          />
          <span class="text-gray-500 p-2" v-if="!checkIfFilesCardEmpty">
            There are no files related to the publication yet.</span
          >
        </div>
      </div>
      <!-- END Card Files -->
    </div>
  </template>
</template>

<script setup lang="ts">
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { ArrowUpIcon, BeakerIcon, BookOpenIcon } from '@heroicons/vue/solid';
import { DefaultApolloClient, useQuery } from '@vue/apollo-composable';
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import UrlTemplate from 'url-template';
import { Ref, computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';
import {
  FileEdge,
  FulfillmentMethod,
  LcpRepresentation,
  AcsRepresentation,
  CantookAudioRepresentation,
} from '../../__generated__/graphql';

import FileList from '@/components/files/FileList.vue';
import { useAuth } from '../../auth';
import {
  colorAccordingToPublicationNature,
  splitPublicationNature,
} from '../../utils';
import FileUpload from '../base/FileUpload.vue';
import { GLOBAL_ADD_PAGE_ACTION } from '../../main';

const addPageAction = inject(GLOBAL_ADD_PAGE_ACTION)!;

const fileList: Ref<InstanceType<typeof FileList> | null> = ref(null);

const toast = useToast();
const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const checkIfFilesCardEmpty: Ref<boolean> = ref(true);

function base64safe(str: string) {
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function getSampleWebReaderUrl(id: string) {
  let encodedId = base64safe(id);
  return `https://r.cantook.com/warehouse/sample/${encodedId}`;
}

function getSampleWebReaderUrlStaging(publicationId: string) {
  let encodedId = base64safe(publicationId);
  return `https://web-reader.demarque.workers.dev/warehouse/sample/${encodedId}`;
}

function getSampleWebReaderUrlPreProd(publicationId: string) {
  let encodedId = base64safe(publicationId);
  return `https://r-pre.cantook.com/warehouse/sample/${encodedId}`;
}

const showWebreaderDropdown = ref<boolean>(false);

const uploadedCover = ref<any>(null);
const uploadedContent = ref<any>(null);
const uploadedSample = ref<any>(null);

const route = useRoute();
const router = useRouter();
const publicationId = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const { result, loading, error } = useQuery(
  graphql(`
    query GetPublication($id: ID!) {
      publication(id: $id) {
        id
        isbn
        namespace {
          id
          name
        }

        files {
          edges {
            node {
              md5Hash
              nature
            }
          }
        }

        archivedFiles {
          edges {
            node {
              md5Hash
              nature
            }
          }
        }

        fulfillmentMethods

        cover {
          templated
          href
        }

        sample {
          href
        }

        metadata {
          nature
          title
          summary
          author {
            name
          }
          publisher
          published
        }

        extractedMetadata {
          extractedFromFileId
        }

        licenses {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `),
  () => ({
    id: publicationId.value,
  })
);

const publication = computed(() => result.value?.publication ?? undefined);

const potentialFulfillments: Ref<
  Array<{ key: FulfillmentMethod; label: string }>
> = computed(() => {
  let value = result.value;
  if (!value) {
    return [];
  }

  let metadata = value.publication?.metadata;
  if (!metadata) {
    return [];
  }

  const nature = metadata?.nature ?? 'unknown';

  if (nature == 'AUDIO_BOOK') {
    return [
      { key: 'DIRECT_DOWNLOAD', label: 'Direct download' },
      { key: 'WEBREADER', label: 'Web reader' },
      { key: 'AUDIO_MANIFEST', label: 'Audio manifest (for use in-app)' },
      { key: 'LCP', label: 'LCP' },
    ];
  }

  return [
    { key: 'DIRECT_DOWNLOAD', label: 'Direct download' },
    { key: 'WEBREADER', label: 'Web reader' },
    { key: 'ACS4', label: 'ACS4' },
    { key: 'LCP', label: 'LCP' },
  ];
});

const openInWebReader = async function () {
  const urlResult = await apolloClient.query({
    query: graphql(`
      query WebReaderUrl($id: ID!, $expires: DateTime!) {
        publication(id: $id) {
          id
          webReaderSignedURL(expires: $expires)
        }
      }
    `),
    variables: {
      id: publicationId.value,
      expires: dayjs().add(1, 'day'),
    },
  });
  const tab = window.open(
    urlResult.data.publication?.webReaderSignedURL,
    '_blank'
  );
  if (tab) {
    tab.focus();
  }
};

const openInNewWebReader = async function () {
  const urlResult = await apolloClient.query({
    query: graphql(`
      query NewWebReaderUrl($id: ID!, $expires: DateTime!) {
        publication(id: $id) {
          id
          newWebReaderSignedURL(expires: $expires)
        }
      }
    `),
    variables: {
      id: publicationId.value,
      expires: dayjs().add(1, 'day'),
    },
  });
  const tab = window.open(
    urlResult.data.publication?.newWebReaderSignedURL,
    '_blank'
  );
  if (tab) {
    tab.focus();
  }
};

const openInNewWebReaderStaging = async function () {
  const urlResult = await apolloClient.query({
    query: graphql(`
      query WebReaderStagingUrl($id: ID!, $expires: DateTime!) {
        publication(id: $id) {
          id
          newWebReaderStagingSignedURL(expires: $expires)
        }
      }
    `),
    variables: {
      id: publicationId.value,
      expires: dayjs().add(1, 'day'),
    },
  });
  const tab = window.open(
    urlResult.data.publication?.newWebReaderStagingSignedURL,
    '_blank'
  );
  if (tab) {
    tab.focus();
  }
};

const openInNewWebReaderPreProd = async function () {
  const urlResult = await apolloClient.query({
    query: graphql(`
      query WebReaderPreProdUrl($id: ID!, $expires: DateTime!) {
        publication(id: $id) {
          id
          newWebReaderPreProdSignedURL(expires: $expires)
        }
      }
    `),
    variables: {
      id: publicationId.value,
      expires: dayjs().add(1, 'day'),
    },
  });
  const tab = window.open(
    urlResult.data.publication?.newWebReaderPreProdSignedURL,
    '_blank'
  );
  if (tab) {
    tab.focus();
  }
};

const openSampleInNewWebReaderHelper = async function (
  urlFetcher: (publicationId: string) => string
) {
  const publicationId = result.value?.publication?.id;
  if (publicationId) {
    const tab = window.open(urlFetcher(publicationId), '_blank');
    if (tab) {
      tab.focus();
    }
  }
};

const openSampleInNewWebReader = async function () {
  openSampleInNewWebReaderHelper(getSampleWebReaderUrl);
};

const openSampleInNewWebReaderStaging = async function () {
  openSampleInNewWebReaderHelper(getSampleWebReaderUrlStaging);
};

const openSampleInNewWebReaderPreProd = async function () {
  openSampleInNewWebReaderHelper(getSampleWebReaderUrlPreProd);
};

const getUrlFetcher = function (
  type: 'COVER' | 'CONTENT' | 'SAMPLE',
  forceOverwrite: boolean = false
) {
  return async function (originalFile: any) {
    if (result.value) {
      try {
        const urlResult = await apolloClient.query({
          query: graphql(`
            query UploadUrl(
              $id: ID!
              $mimeType: Mime!
              $type: UploadablePublicationResourceType!
              $forceOverwrite: Boolean
            ) {
              publication(id: $id) {
                id
                uploadUrl(
                  input: {
                    type: $type
                    mimeType: $mimeType
                    forceOverwrite: $forceOverwrite
                  }
                )
              }
            }
          `),
          variables: {
            id: publicationId.value,
            mimeType: originalFile.type,
            type,
            forceOverwrite,
          },
        });
        return urlResult.data.publication?.uploadUrl;
      } catch (error: any) {
        if (error.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN') {
          popErrorToast('Forbidden', 'User lacks the right to upload files.');
        } else {
          popErrorToast('Error', error?.message);
          throw error;
        }
      }
    }
  };
};

const files = computed(() => result.value?.publication?.files.edges ?? []);
const archivedFiles = computed(
  () => result.value?.publication?.archivedFiles.edges ?? []
);

const fileUploadValidation =
  (contentType: 'CONTENT' | 'COVER' | 'SAMPLE') => async (loadedFile: any) => {
    try {
      /* For larger files, we skip the validation because it can take several minutes.*/
      if (loadedFile.size > 1024 * 1024 * 400) {
        return null;
      }
      const base16MD5Hash = await fileMD5(loadedFile);

      const matchArchivedFiles = archivedFiles.value.some(
        (file: any) =>
          file.node.nature == contentType && file.node.md5Hash == base16MD5Hash
      );

      if (matchArchivedFiles) {
        return `The file you're trying to add has already been uploaded but has been archived.`;
      }

      const matchActiveFiles = files.value.some(
        (file: any) =>
          file.node.nature == contentType && file.node.md5Hash == base16MD5Hash
      );

      return matchActiveFiles
        ? `File already uploaded, might be ignored.`
        : null;
    } catch (e) {
      toast.error('An error has occurred while validating the file.\n' + e);
      throw e;
    }
  };

const fileMD5 = async (file: any) => {
  const chunkSize = 2 * 1024 * 1024;
  let offset = 0;
  let md5Hash = CryptoJS.algo.MD5.create();
  const fileReader = new FileReader();

  const readNextChunk = () => {
    const chunk = file.slice(offset, Math.min(offset + chunkSize, file.size));
    fileReader.readAsArrayBuffer(chunk);
  };

  return new Promise<string>((resolve, reject) => {
    fileReader.onerror = (event) =>
      reject(new Error(event?.target?.error?.message));

    fileReader.onload = (event) => {
      const chunk = event?.target?.result;
      const wordArray = CryptoJS.lib.WordArray.create(chunk as any);
      md5Hash.update(wordArray);

      offset += chunkSize;

      if (offset < file.size) {
        readNextChunk();
      } else {
        resolve(md5Hash.finalize().toString(CryptoJS.enc.Hex));
      }
    };

    readNextChunk();
  });
};

const forceLicensesUpdateLoading = ref(false);

const forceLicensesUpdate = async () => {
  if (publicationId.value) {
    forceLicensesUpdateLoading.value = true;
    let forceUpdateLicensesResult = await apolloClient.mutate({
      mutation: graphql(`
        mutation ForceLicensesUpdate($ids: [ID!]!) {
          forceLicensesUpdate(ids: $ids)
        }
      `),
      variables: {
        ids:
          result.value?.publication?.licenses.edges.map(
            ({ node }) => node.id
          ) ?? [],
      },
    });
    if (forceUpdateLicensesResult?.data?.forceLicensesUpdate) {
      toast.success('Licenses updated successfully');
      forceLicensesUpdateLoading.value = false;
    }
  }
};

const canOpenInWebReader = function (): boolean {
  return (
    !!result.value &&
    !!result.value?.publication?.fulfillmentMethods.includes(
      'WEBREADER' as FulfillmentMethod
    ) &&
    'AUDIO_BOOK' !== result.value?.publication?.metadata?.nature
  );
};

if (canOpenInWebReader()) {
  addPageAction({
    label: 'Read the publication',
    action: openInNewWebReader,
  });
}

const handleUploadedCover = function (file: any) {
  uploadedCover.value = file;
  if (uploadedCover.value) {
    toast('Cover uploaded successfully');
    fileList.value?.updateFiles();
  }
};

const handleUploadedContent = function (file: any) {
  uploadedContent.value = file;
  if (uploadedContent.value) {
    toast('Content uploaded successfully');
    fileList.value?.updateFiles();
  }
};

const handleUploadedSample = function (file: any) {
  uploadedSample.value = file;
  if (uploadedSample.value) {
    toast('Sample uploaded successfully');
  }
};

const handleFailedUpload = (msg: string) => {
  toast.error(msg);
};

const coverSrc = computed(() => {
  let cover = result?.value?.publication?.cover;
  return cover?.templated
    ? UrlTemplate.parse(cover.href).expand({
        variant: 'medium',
      })
    : cover?.href;
});

let {
  canWriteResourceTypeOnAtLeastOneNamespace,
  canReadResourceTypeOnAtLeastOneNamespace,
} = useAuth();

const hasLicensesReadRight =
  canReadResourceTypeOnAtLeastOneNamespace('licenses');

const hasLicensesWriteRight =
  canWriteResourceTypeOnAtLeastOneNamespace('licenses');

const hasPublicationWriteRigth =
  canWriteResourceTypeOnAtLeastOneNamespace('publications');

const toggleSummary = ref(false);

const summaryRef = ref('');

const collapsedSummary = ref('');

const summary = computed(() => {
  if (result.value?.publication?.metadata?.summary) {
    if (result.value.publication.metadata?.summary.length > 500) {
      const lastSpace = result.value.publication.metadata?.summary.lastIndexOf(
        ' ',
        500
      );
      collapsedSummary.value = result.value.publication.metadata.summary
        .substring(0, lastSpace)
        .concat(' (...)');
      summaryRef.value = collapsedSummary.value;
    } else {
      summaryRef.value = result.value.publication.metadata?.summary;
    }
    return result.value.publication.metadata?.summary;
  }
});

const getSummary = () => {
  if (summary) {
    if (toggleSummary.value == false) {
      toggleSummary.value = true;
      summaryRef.value = summary.value as string;
    } else {
      toggleSummary.value = false;
      summaryRef.value = collapsedSummary.value;
    }
  }
};

const showFile = (fileId: string | null) => {
  if (!fileId) {
    return;
  }
  router.push({
    name: 'file',
    params: { id: fileId },
  });
};

const availabilityCheckForfulfillmentMethods = (key: FulfillmentMethod) => {
  if (publication && publication.value?.fulfillmentMethods) {
    return publication.value.fulfillmentMethods.indexOf(key) !== -1;
  }
  return false;
};
</script>

<style>
.webreader-menu-fade-enter-active,
.webreader-menu-fade-leave-active {
  transition: opacity 0.3s ease;
}

.webreader-menu-fade-enter-from,
.webreader-menu-fade-leave-to {
  opacity: 0;
}

.emerald-enabled {
  @apply border-emerald-700;
  @apply bg-emerald-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-emerald-800;
  @apply hover:border-emerald-800;
  @apply focus:ring focus:ring-emerald-500 focus:ring-opacity-50;
  @apply active:bg-emerald-700 active:border-emerald-700;
}

.amber-enabled {
  @apply border-amber-700;
  @apply bg-amber-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-amber-800;
  @apply hover:border-amber-800;
  @apply focus:ring focus:ring-amber-500 focus:ring-opacity-50;
  @apply active:bg-amber-700 active:border-amber-700;
}

.custom-cover-height {
  height: 30rem;
}

.container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.container:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.cover-btn {
  position: absolute;
  width: inherit !important;
  height: fit-content;
  left: 0;
  top: 0;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  border-radius: 0.25rem !important;
  @apply text-indigo-600 !important;
}

.overlay:hover .cover-btn {
  opacity: 0.8;
}

.vertical-line {
  border-right: 2px solid #6b7280;
  height: fit-content;
}

.simple-btn {
  @apply w-fit;
  @apply block;
  @apply p-3;
  @apply shadow-sm;
  @apply border;
  @apply border-slate-100;
  @apply font-medium;
  @apply text-sm;
  @apply text-center;
  @apply bg-gray-50;
  @apply text-indigo-600;
}

.active-btn {
  @apply hover:bg-gray-100;
  @apply hover:bg-opacity-75;
  @apply active:bg-gray-50;
  @apply hover:text-indigo-500;
}

.disabled-btn {
  @apply opacity-75;
  @apply cursor-not-allowed;
}

.summary {
  text-align: justify;
  padding: 0.5rem;
}
</style>
