<template>
  <h2 class="page-title" v-if="publication">
    New Share for {{ publication.isbn }} -
    {{ publication.metadata?.title ?? 'Untitled' }}
  </h2>

  <form @submit.prevent="createShare()" class="space-y-6 mb-4">
    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-from">From</label>
      <date-input id="tk-form-elements-from" v-model="from" />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-to">To</label>
      <date-input id="tk-form-elements-to" v-model="to" />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-covert">Covert</label>
      <input
        class="input-checkbox ml-3"
        type="checkbox"
        v-model="covert"
        id="tk-form-elements-covert"
        placeholder="Covert share"
      />
    </div>

    <dm-button type="submit" :loading="createShareLoading"
      >Create Share</dm-button
    >
  </form>

  <error-display
    headerMessage="Failed to create new Share"
    :error="createShareError"
  />
</template>

<script lang="ts">
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import { ref, computed, defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs';

import { graphql } from '../../__generated__/gql';

import ErrorDisplay from '../ErrorDisplay.vue';
import DateInput from '../base/DateInput.vue';

export default defineComponent({
  name: 'NewUser',
  components: {
    ErrorDisplay,
    DateInput,
  },
  setup() {
    const route = useRoute();

    const publicationId = computed(() =>
      Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
    );

    const from = ref<string>();
    const to = ref<string>();
    const covert = ref<boolean>(true);

    const { result } = useQuery(
      graphql(`
        query publication($publicationId: ID!) {
          publication(id: $publicationId) {
            id
            isbn
            metadata {
              title
            }
          }
        }
      `),
      {
        publicationId: publicationId.value,
      }
    );

    const publication = useResult(result);

    const {
      mutate: createShare,
      loading: createShareLoading,
      error: createShareError,
      onDone,
    } = useMutation(
      graphql(`
        mutation createShare(
          $covert: Boolean!
          $publicationId: ID!
          $from: DateTime
          $to: DateTime
        ) {
          createShare(
            input: {
              publicationId: $publicationId
              covert: $covert
              from: $from
              to: $to
            }
          ) {
            success
          }
        }
      `),
      () => ({
        variables: {
          publicationId: publicationId.value,
          covert: covert.value,
          from: from.value && dayjs(from.value).toISOString(),
          to: to.value && dayjs(to.value).toISOString(),
        },
      })
    );

    const router = useRouter();

    onDone((result) => {
      router.push({
        name: 'publication-shares',
        params: {
          id: publicationId.value,
        },
      });
    });

    return {
      covert,
      from,
      to,
      createShareLoading,
      createShareError,
      createShare,
      publication,
    };
  },
});
</script>

<style scoped>
.input-text {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.input-checkbox {
  @apply block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

input:invalid {
  @apply w-full block border rounded px-3 py-2 leading-6 text-red-700 border-red-400 focus:border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-50;
}
</style>
