<template>
  <h2 class="page-title">Publications</h2>

  <namespace-selector
    class="mb-4"
    v-model="namespaceSelectedRef"
    :resource-type="resourceType"
    inject-option-all-namespaces
  />

  <Table
    :headers="['ISBN', 'Title', 'Nature']"
    :hasNextPage="!!publicationsPageInfo?.hasNextPage"
    :hasPreviousPage="!!publicationsPageInfo?.hasPreviousPage"
    @changePage="changePage"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>Error: {{ error }}</td>
    </tr>
    <tr
      v-else-if="publications"
      v-for="publication of publications"
      :key="publication.node.id"
      class="border-b border-gray-200"
    >
      <td class="p-3">
        <router-link
          :to="{
            name: 'publication',
            params: { id: publication.node.id },
          }"
        >
          {{ publication.node.isbn }}
        </router-link>
      </td>
      <td class="p-3 text-gray-500">
        {{ publication.node.metadata?.title || 'Untitled' }}
      </td>
      <td class="p-3 text-gray-500 text-left box-content">
        <span
          v-if="publication.node.metadata?.nature"
          class="font-semibold inline-flex px-3 py-1 leading-4 items-center space-x-1 text-sm rounded"
          :class="
            colorAccordingToPublicationNature(publication.node.metadata.nature)
          "
        >
          <span>
            {{ splitPublicationNature(publication.node.metadata?.nature) }}
          </span>
        </span>
        <span v-else class="italic">Unknown</span>
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, Ref } from 'vue';

import { graphql } from '../../__generated__/gql';

import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import { existingResourceTypes } from '../../auth';
import {
  splitPublicationNature,
  colorAccordingToPublicationNature,
} from '../../utils';
import { PublicationsQueryVariables } from '../../__generated__/graphql';
import Table from '../base/Table.vue';

const perPage = 25;
const resourceType: existingResourceTypes = 'publications';
const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);

const namespaceId = computed(
  () => namespaceSelectedRef.value && namespaceSelectedRef.value.id
);

const variables: Ref<PublicationsQueryVariables> = computed(() => ({
  first: perPage,
  last: null,
  before: undefined,
  after: undefined,
  namespaceId: namespaceId.value,
}));

const { result, loading, error, fetchMore } = useQuery(
  graphql(`
    query Publications(
      $namespaceId: ID
      $first: Int
      $last: Int
      $after: String
      $before: String
    ) {
      publications(
        filterBy: { namespaceId: $namespaceId }
        first: $first
        last: $last
        before: $before
        after: $after
        orderBy: { direction: DESC, field: UPDATED_AT }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            isbn
            metadata {
              title
              nature
            }
          }
        }
      }
    }
  `),
  variables
);

const changePage = (e: 'next' | 'prev') => {
  if (e == 'next') {
    fetchMore({
      variables: {
        first: perPage,
        last: null,
        after: result.value?.publications.pageInfo?.endCursor,
      },
    });
  } else {
    fetchMore({
      variables: {
        first: null,
        last: perPage,
        before: result.value?.publications.pageInfo?.startCursor,
      },
    });
  }
};

const publicationsPageInfo = computed(() => {
  if (result?.value?.publications?.pageInfo) {
    return result.value.publications.pageInfo;
  } else {
    return null;
  }
});

const publications = computed(() => result.value?.publications.edges ?? []);
</script>
