<template>
  <div v-if="loading">Loading...</div>
  <template v-else-if="!loan">
    <div
      class="p-4 md:p-5 rounded text-orange-700 bg-orange-100 dark:text-orange-100 dark:bg-orange-900 dark:bg-opacity-75"
    >
      <div class="flex items-center">
        <svg
          class="hi-solid hi-exclamation inline-block w-5 h-5 mr-3 flex-none text-orange-500 dark:text-orange-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <h3 class="font-semibold grow">Loan not found</h3>
      </div>
    </div>
  </template>
  <template v-else-if="loan">
    <div>
      <h4
        class="text-gray-400 uppercase text-semi-bold font-semibold text-sm tracking-widest"
      >
        Loan
      </h4>
      <h2 class="page-title">
        <em>{{ shortId(loan.id) }}</em>
      </h2>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
      <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              {{ loan.status }}
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Status
            </dd>
          </dl>
        </div>
      </div>

      <div class="flex flex-col rounded shadow-sm bg-white">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date :date="loan.expires" clickable />
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Expiration
            </dd>
          </dl>
        </div>
      </div>

      <div class="flex flex-col rounded shadow-sm bg-white">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date :date="loan.updatedAt" clickable />
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Last Update
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <h3 class="text-xl font-bold py-2 border-b-2 border-gray-200 mt-4">
      Details
    </h3>
    <dl class="details">
      <dt>Borrower</dt>
      <dd>{{ loan.borrowerId }}</dd>

      <dt>Transaction</dt>
      <dd>{{ loan.transactionId }}</dd>

      <template v-if="loan.license">
        <dt>License</dt>
        <dd>
          <router-link
            :to="{ name: 'license', params: { id: loan.license.id } }"
            >{{ shortId(loan.license.id) }}</router-link
          >
        </dd>

        <template
          v-if="
            loan.entityId ??
            loan.license.entityId ??
            loan.license.publication?.id
          "
        >
          <dt>Publication</dt>
          <dd>
            <show-licenses-publication
              :target="loan.license.target"
              :entity-id="
                loan.entityId ??
                loan.license.entityId ??
                loan.license.publication?.id
              "
              :namespace-id="loan.license.namespace.id"
              :load-cover="false"
              title-style="underline"
              header-style="hidden"
              cover-wrap-style="hidden"
            />
          </dd>
        </template>

        <dt>Namespace</dt>
        <dd>
          <router-link
            :to="{
              name: 'namespace',
              params: { id: loan.license.namespace.id },
            }"
            >{{ loan.license.namespace.name }}</router-link
          >
        </dd>
      </template>

      <template v-if="opdsFeed">
        <dt>OPDS feed</dt>
        <dd>
          <a :href="opdsFeed" target="_blank" rel="noopener noreferrer">
            {{ opdsFeed }}
          </a>
        </dd>
      </template>
    </dl>

    <button-divider
      title="License Status Document"
      @showContent="getLsd()"
      @buttonStatus="getCard($event)"
    />

    <div
      v-if="showCard"
      class="flex flex-col rounded-lg shadow-sm bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800"
    >
      <!-- CARD BODY -->
      <div class="py-4 px-5 bg-gray-50 dark:bg-gray-700/50 text-right">
        <button-copy-clipboard :dataToCopy="dataToCopyRef" />
      </div>
      <!-- Card Body -->
      <div class="p-5 grow overflow-auto" v-if="dataStatusRef">
        <code class="pretty-json" v-html="dataStatusRef"> </code>
      </div>
      <!-- Card Footer -->
      <div
        class="py-4 px-5 text-sm text-gray-600 bg-gray-50 dark:text-gray-400 dark:bg-gray-700/50"
      ></div>
    </div>
  </template>
  <div v-else-if="error">
    {{ error }}
  </div>
</template>

<script setup lang="ts">
import {
  useQuery,
  useResult,
  DefaultApolloClient,
} from '@vue/apollo-composable';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { computed, ref, Ref, inject } from 'vue';
import { useRoute } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import ShowLicensesPublication from '../licenses/publications/ShowLicensesPublication.vue';
import ButtonDivider from '@/components/base/ButtonDivider.vue';
import ButtonCopyClipboard from '@/components/base/ButtonCopyClipboard.vue';
import { shortId } from '../../utils';
import { GLOBAL_FETCH } from '../../main';

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const route = useRoute();

const globalFetch = inject(GLOBAL_FETCH)!;

const loanId = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const dataStatusRef: Ref<string> = ref('');
const dataToCopyRef: Ref<string> = ref('');
const showCard: Ref<boolean> = ref(false);

const getCard = (value: boolean) => {
  showCard.value = value;
};

const fulfillUrl = import.meta.env.VITE_FULFILLMENT_BASE_URL;

const getLsd = async () => {
  if (!loanId.value) {
    return;
  }
  if (dataStatusRef.value) {
    return;
  }
  const regexJsonKey = /"(?!<a[^>]*>)([^\\"\n]|\\.)*"(?=:)/g;
  const regexUrl =
    /(?<=\bhref":\s*")(?:https?|http):\/\/(?:(?!\{)[^\s"])+(?!.*{)/gm;

  const encodeId = encodeURIComponent(loanId.value);
  const response = await globalFetch(`${fulfillUrl}/loans/${encodeId}/status`);
  const dataStatus = await response.json();

  dataToCopyRef.value = JSON.stringify(dataStatus, null, 2);
  const dataString = JSON.stringify(dataStatus, null, 2)
    .replace(regexUrl, '<a style="color:blue" href=\'$&\'>$&</a>')
    .replace(regexJsonKey, '<span class="jsonTitle">$&</span>');
  dataStatusRef.value = dataString;
};

const { result, loading, error, onResult } = useQuery(
  graphql(`
    query GetLoan($loanId: ID!) {
      loan(id: $loanId) {
        id
        entityId
        updatedAt
        borrowerId
        transactionId
        status
        expires
        license {
          id
          entityId
          target
          namespace {
            id
            name
          }
          publication {
            id
          }
        }
      }
    }
  `),
  () => ({ loanId: loanId.value })
);

const loan = useResult(result);

const opdsFeed = computed(
  () =>
    import.meta.env.VITE_FULFILLMENT_BASE_URL +
    '/loans/' +
    encodeURIComponent(loanId.value) +
    '/opds'
);
</script>
<style scoped>
.jsonTitle {
  color: rgb(70, 101, 157);
}

.pretty-json {
  white-space: pre-wrap;
  font-size: small;
  color: rgb(75, 85, 94);
}

.details dt {
  @apply font-semibold mt-2;
}

.details dd {
  @apply text-slate-800 text-sm;
}

.details a {
  @apply underline;
}
</style>
