<template>
  <h2 class="page-title">New User</h2>

  <form @submit.prevent="createUser()" class="space-y-6 mb-4">
    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-name">Name</label>
      <input
        class="input-text"
        type="text"
        v-model="name"
        id="tk-form-elements-name"
        placeholder="Enter the user's name"
      />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-email">Email</label>
      <input
        class="input-text"
        type="email"
        v-model="email"
        id="tk-form-elements-email"
        placeholder="Enter the user's email"
      />
    </div>

    <dm-button
      :enabled="validEmail && validName"
      type="submit"
      :loading="createUserLoading"
      >Create User</dm-button
    >
  </form>

  <error-display
    headerMessage="Failed to invite new user"
    :error="createUserError"
  />
</template>

<script lang="ts">
import { useMutation } from '@vue/apollo-composable';
import { ref, computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import ErrorDisplay from '../ErrorDisplay.vue';

export default defineComponent({
  name: 'NewUser',
  components: {
    ErrorDisplay,
  },
  setup() {
    const name = ref<string>('');
    const email = ref<string>('');
    const validEmail = computed(() =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.value
      )
    );
    const validName = computed(() => name.value.length >= 3);

    const {
      mutate: createUser,
      loading: createUserLoading,
      error: createUserError,
      onDone,
    } = useMutation(
      graphql(`
        mutation CreateUser($name: String!, $email: String!) {
          createUser(input: { email: $email, name: $name }) {
            id
          }
        }
      `),
      () => ({
        variables: {
          email: email.value,
          name: name.value,
        },
      })
    );

    const router = useRouter();

    onDone((result) => {
      router.push({
        name: 'user',
        params: {
          id: `${result.data?.createUser.id}`,
        },
      });
    });

    return {
      name,
      email,
      createUser,
      validEmail,
      validName,
      createUserLoading,
      createUserError,
    };
  },
});
</script>

<style scoped>
.input-text {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

input:invalid {
  @apply w-full block border rounded px-3 py-2 leading-6 text-red-700 border-red-400 focus:border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-50;
}
</style>
