<template>
  <message-confirmation
    :isHidden="hideAlertMessage"
    :bodyInfoMessage="`Are you sure you want to trigger the cover repackaging of the ${selectedPublications.length} selected publication(s) ?`"
    :titleInfoMessage="`Repack of ${selectedPublications.length} cover(s)`"
    buttonConfirmMessage="Yes, trigger repackaging"
    :showCancelButton="true"
    @answerUser="answerCloudflareImageRepackagingAlertMessage"
    variant="warning"
    :large="true"
  />

  <namespace-selector
    class="mb-2"
    v-model="namespaceSelectedRef"
    resource-type="publications"
    inject-option-all-namespaces
  />
  <div
    v-if="selectedPublications.length > 0"
    class="bg-gray-100 p-3 my-2 dark:bg-gray-700/50 rounded border border-gray-200 flex justify-between"
  >
    <span class="text-ml">
      <b> {{ selectedPublications.length }} </b> publications selected on
      <b>{{ namespaceSelectedRef?.name || 'all namespaces' }} </b>
    </span>
    <span>
      <dm-button variant="danger" size="s" @click="showCoverRepackagingMessage">
        Repack {{ selectedPublications.length }} {{ repackType }}
      </dm-button>
    </span>
  </div>
  <!-- Tables: With Checkboxes -->
  <div
    class="min-w-full overflow-x-auto rounded border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800"
  >
    <!-- Table -->
    <table class="min-w-full whitespace-nowrap align-middle text-sm">
      <!-- Table Header -->
      <thead>
        <tr>
          <th class="table-header">
            <input
              type="checkbox"
              name="check_all"
              class="size-4 rounded border border-gray-400 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-800 dark:ring-offset-gray-900 dark:checked:border-transparent dark:checked:bg-blue-500 dark:focus:border-blue-500"
              @click="selectAllPublications"
              :checked="isCheckAll"
            />
          </th>
          <th class="table-header">ISBN - Title</th>
          <th class="table-header">Created At</th>
          <th class="table-header">Updated At</th>
          <th class="table-header text-center">Packaging status</th>
          <th class="table-header">Nature</th>
        </tr>
      </thead>
      <!-- END Table Header -->

      <!-- Table Body -->
      <tbody>
        <tr v-if="loading">
          <td>Loading...</td>
        </tr>
        <tr v-else-if="error">
          <td>{{ error }}</td>
        </tr>
        <tr
          v-else-if="publications"
          v-for="publication of publications"
          :key="publication.id"
          class="even:bg-gray-50 dark:even:bg-gray-900/50"
        >
          <td class="p-3 text-left">
            <input
              type="checkbox"
              :checked="selectedPublications.includes(publication.id)"
              :id="`${publication.id}`"
              class="size-4 rounded border border-gray-400 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-800 dark:ring-offset-gray-900 dark:checked:border-transparent dark:checked:bg-blue-500 dark:focus:border-blue-500"
              @click="addSelectedPublicationToList(publication.id)"
            />
          </td>
          <td class="p-3 text-gray-500 dark:text-gray-400">
            <router-link
              :to="{ name: 'publication', params: { id: publication.id } }"
            >
              <b>{{ publication.isbn }} </b> -
              {{ publication.metadata?.title || 'Untitled' }}
            </router-link>
          </td>
          <td class="p-3 text-gray-500 dark:text-gray-400">
            {{ publication.created }}
          </td>
          <td class="p-3 text-gray-500 dark:text-gray-400">
            {{ publication.updated }}
          </td>
          <td class="text-center">
            <span>
              <div class="group relative inline-block">
                <svg
                  class="hi-solid hi-x-circle mr-3 w-6 h-6 inline-block flex-none"
                  :class="
                    colorAccordingToFileRepresentationSuccess(
                      publicationHasRepresentation(publication.files.edges)
                    )
                  "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div
                  class="duration-50 invisible absolute bottom-full left-1/2 z-1 -ml-20 flex w-40 origin-bottom translate-y-2 scale-75 flex-col items-center justify-center pb-0.5 opacity-75 transition ease-out will-change-auto group-hover:visible group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100"
                >
                  <div
                    class="flex-none rounded-lg bg-gray-500 px-2.5 py-2 text-center text-xs font-semibold text-gray-50 opacity-75 dark:bg-gray-700"
                  >
                    {{
                      tooltipAccordingToRepackStatus(
                        publicationHasRepresentation(publication.files.edges)
                      )
                    }}
                  </div>
                  <div
                    class="h-0 w-0 flex-none border-l-4 border-r-4 border-t-4 border-l-transparent border-r-transparent border-t-gray-800 dark:border-t-gray-700"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>
            </span>
          </td>
          <td class="p-3 text-gray-500 text-left box-content">
            <span
              v-if="publication.metadata?.nature"
              class="font-semibold inline-flex px-3 py-1 leading-4 items-center space-x-1 text-sm rounded"
              :class="
                colorAccordingToPublicationNature(publication.metadata.nature)
              "
            >
              <span>
                {{ splitPublicationNature(publication.metadata?.nature) }}
              </span>
            </span>
            <span v-else class="italic">Unknown</span>
          </td>
        </tr>
      </tbody>
      <!-- END Table Body -->
      <tfoot v-if="!loading && publications && publicationsPageInfo">
        <tr class="border-b border-gray-200">
          <td
            class="p-3 text-gray-7000 bg-gray-100 font-semibold text-sm"
            :colspan="6"
          >
            <!-- Pagination -->
            <div class="flex w-full gap-2 justify-center items-center">
              <button
                :disabled="!publicationsPageInfo.hasPreviousPage"
                @click="
                  fetchMore({
                    variables: {
                      last: perPage,
                      first: null,
                      before: publicationsPageInfo?.startCursor,
                    },
                  }),
                    resetAllFields()
                "
                type="button"
                :class="
                  publicationsPageInfo.hasPreviousPage
                    ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
                    : 'text-gray-300'
                "
                class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
              >
                <arrow-left-icon class="w-5 h-5" />
              </button>
              <button
                :disabled="!publicationsPageInfo.hasNextPage"
                @click="
                  fetchMore({
                    variables: {
                      first: perPage,
                      last: null,
                      after: publicationsPageInfo?.endCursor,
                    },
                  }),
                    resetAllFields()
                "
                :class="
                  publicationsPageInfo.hasNextPage
                    ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
                    : 'text-gray-300'
                "
                type="button"
                class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
              >
                <arrow-right-icon class="w-5 h-5" />
              </button>
            </div>
            <!-- END Pagination -->
          </td>
        </tr>
      </tfoot>
    </table>
    <!-- END Table -->
  </div>
  <!-- END Tables: With Checkboxes -->
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, ref, Ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';

import { graphql } from '../../__generated__/gql';
import { PublicationsForRepackagingQueryVariables } from '../../__generated__/graphql';

import MessageConfirmation from '@/components/base/DialogMessage.vue';
import NamespaceSelector from '@/components/namespaces/NamespaceSelector.vue';
import {
  splitPublicationNature,
  colorAccordingToPublicationNature,
} from '../../utils';

enum RepackStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NO_FILE = 'NO_FILE',
}

const props = defineProps({
  repackType: {
    type: String,
    required: true,
  },
});

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);

const namespaceId = computed(
  () => namespaceSelectedRef.value && namespaceSelectedRef.value.id
);

watch(namespaceId, () => {
  resetAllFields();
});

const toast = useToast();

const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const popToast = (toastTitle: string, toastContent: string) => {
  toast(toastTitle.toUpperCase() + '\n' + toastContent);
};

const perPage = 100;

const selectedPublications: Ref<string[]> = ref([]);
const fileIds: Ref<string[]> = ref([]);
const isCheckAll: Ref<boolean> = ref(false);
const hideAlertMessage: Ref<boolean> = ref(true);

const resetAllFields = () => {
  selectedPublications.value = [];
  isCheckAll.value = false;
};

const variables: Ref<PublicationsForRepackagingQueryVariables> = computed(
  () => ({
    first: perPage,
    last: null,
    before: undefined,
    after: undefined,
    namespaceId: namespaceId.value,
  })
);

const { result, loading, error, fetchMore } = useQuery(
  graphql(`
    query PublicationsForRepackaging(
      $first: Int
      $last: Int
      $before: String
      $after: String
      $namespaceId: ID
    ) {
      publications(
        filterBy: { namespaceId: $namespaceId }
        first: $first
        last: $last
        before: $before
        after: $after
        orderBy: { direction: DESC, field: UPDATED_AT }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            isbn
            updated
            created
            metadata {
              title
              nature
            }
            files {
              edges {
                node {
                  id
                  nature
                  representations {
                    __typename
                  }
                }
              }
            }
          }
        }
      }
    }
  `),
  variables
);

const publications = computed(() => {
  if (result.value?.publications?.edges) {
    return result.value.publications.edges.map(
      (publication) => publication?.node!
    );
  } else {
    return [];
  }
});

const publicationsPageInfo = computed(
  () => result.value?.publications?.pageInfo ?? null
);

const addSelectedPublicationToList = (publicationId: string) => {
  if (!selectedPublications.value.includes(publicationId)) {
    selectedPublications.value.push(publicationId);
  } else {
    selectedPublications.value.splice(
      selectedPublications.value.indexOf(publicationId),
      1
    );
  }
};

const selectAllPublications = () => {
  if (selectedPublications.value.length === publications.value.length) {
    isCheckAll.value = false;
    selectedPublications.value = [];
  } else {
    isCheckAll.value = true;
    selectedPublications.value = publications.value.map(
      (publications) => publications.id
    );
  }
};

const publicationHasRepresentation = (
  fileList: {
    node: {
      id: string;
      nature: string;
      representations: {
        __typename: string;
      }[];
    };
  }[]
) => {
  if (fileList.length === 0) {
    return RepackStatus.NO_FILE;
  }

  const currentFile = fileList.find((file) => file.node.nature === 'COVER');

  if (currentFile) {
    return fileHasRepresentation(
      currentFile.node?.representations,
      'CloudflareImageRepresentation'
    );
  } else {
    return RepackStatus.NO_FILE;
  }
};

const fileHasRepresentation = (
  fileRepresentations: { __typename: string }[],
  representationTypename: string
) => {
  if (!fileRepresentations) {
    return RepackStatus.ERROR;
  }
  return fileRepresentations.find(
    (fileRepresentation) =>
      fileRepresentation.__typename === representationTypename
  )
    ? RepackStatus.SUCCESS
    : RepackStatus.ERROR;
};

const colorAccordingToFileRepresentationSuccess = (status: RepackStatus) => {
  switch (status) {
    case RepackStatus.SUCCESS:
      return 'text-emerald-500';
    case RepackStatus.ERROR:
      return 'text-red-600';
    case RepackStatus.NO_FILE:
      return 'text-gray-400';
    default:
      return 'text-gray-400';
  }
};

const tooltipAccordingToRepackStatus = (status: RepackStatus) => {
  switch (status) {
    case RepackStatus.SUCCESS:
      return `${props.repackType} package is applied`;
    case RepackStatus.ERROR:
      return `${props.repackType} package is not applied`;
    case RepackStatus.NO_FILE:
      return 'No cover file found for this publication';
  }
};

/* COVER REPACKAGING */
const {
  mutate: triggerCloudflareImageRepackagingByPublicationIds,
  onDone: onTriggerCloudflareImageRepackagingByPublicationsIdsDone,
  onError: onTriggerCloudflareImageRepackagingByPublicationsIdsError,
} = useMutation(
  graphql(`
    mutation triggerCloudflareImageRepackagingByPublicationIds(
      $publicationIds: [ID!]!
    ) {
      triggerCloudflareImageRepackagingByPublicationIds(
        publicationIds: $publicationIds
      ) {
        fileIds
      }
    }
  `),
  () => ({
    variables: {
      publicationIds: selectedPublications.value,
    },
  })
);

onTriggerCloudflareImageRepackagingByPublicationsIdsError((error) => {
  resetAllFields();
  popErrorToast('Repackaging error', error.message);
});

onTriggerCloudflareImageRepackagingByPublicationsIdsDone((result) => {
  if (
    result?.data?.triggerCloudflareImageRepackagingByPublicationIds?.fileIds
  ) {
    fileIds.value =
      result.data.triggerCloudflareImageRepackagingByPublicationIds.fileIds;
    popToast(
      `${props.repackType}`,
      `repackaging of ${fileIds.value.length} files will be applied within the next minute.`
    );
  }
  resetAllFields();
});

const showCoverRepackagingMessage = () => {
  hideAlertMessage.value = false;
};

const answerCloudflareImageRepackagingAlertMessage = async (
  answer: boolean
) => {
  if (answer) {
    hideAlertMessage.value = false;
    await triggerCloudflareImageRepackagingByPublicationIds();
  }
  hideAlertMessage.value = true;
};
</script>

<style>
.table-header {
  @apply bg-gray-100 px-3 py-4 text-left tracking-wider font-semibold text-sm uppercase text-gray-700 dark:bg-gray-700/25 dark:text-gray-50;
}
</style>
