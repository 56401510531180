<template>
  <template v-if="props.file.nature === 'CONTENT'">
    <reload-button
      v-if="checkFileMime(props.file.mime) === 'standardPackaging'"
      :name="'ACS'"
      :disable="!canTriggerRepackaging || disableAction"
      :activateIndicator="true"
      :indicatorColor="
        fileHasRepresentation(props.file.representations, 'AcsRepresentation')
      "
      :tooltip="
        informationTooltip(
          fileHasRepresentation(
            props.file.representations,
            'AcsRepresentation'
          ),
          canTriggerRepackaging,
          'AcsRepresentation'
        )
      "
      @clicked="triggerAcsRepackaging({ fileId: props.file.id })"
      :size="props.buttonSize"
      class="m-1"
    />
    <reload-button
      v-if="
        checkFileMime(props.file.mime) === 'standardPackaging' ||
        checkFileMime(props.file.mime) === 'audioPackaging'
      "
      :name="'LCP'"
      :disable="!canTriggerRepackaging || disableAction"
      :activateIndicator="true"
      :indicatorColor="
        fileHasRepresentation(props.file.representations, 'LcpRepresentation')
      "
      :tooltip="
        informationTooltip(
          fileHasRepresentation(
            props.file.representations,
            'LcpRepresentation'
          ),
          canTriggerRepackaging,
          'LcpRepresentation'
        )
      "
      @clicked="triggerLcpRepackaging({ fileId: props.file.id })"
      :size="props.buttonSize"
      class="m-1"
    />

    <reload-button
      v-if="checkFileMime(props.file.mime) === 'audioPackaging'"
      :name="'CantookAudio'"
      :disable="!canTriggerRepackaging || disableAction"
      :activateIndicator="true"
      :indicatorColor="
        fileHasRepresentation(
          props.file.representations,
          'CantookAudioRepresentation'
        )
      "
      :tooltip="
        informationTooltip(
          fileHasRepresentation(
            file.representations,
            'CantookAudioRepresentation'
          ),
          canTriggerRepackaging,
          'CantookAudioRepresentation'
        )
      "
      :size="props.buttonSize"
      @clicked="
        triggerCantookAudioRepackaging({
          fileId: props.file.id,
        })
      "
      class="m-1"
    />

    <reload-button
      v-if="checkFileMime(props.file.mime) === 'standardPackaging'"
      :name="'Sample'"
      :disable="!canTriggerRepackaging || disableAction"
      :tooltip="
        informationTooltip(
          sampleIsGenerated(props.fileList, props.file.id),
          canTriggerRepackaging,
          'Sample'
        )
      "
      :size="props.buttonSize"
      :activate-indicator="true"
      :indicator-color="sampleIsGenerated(props.fileList, props.file.id)"
      @clicked="triggerExtractRegeneration({ fileId: props.file.id })"
      class="m-1"
    />

    <template v-if="file.mime == 'application/epub+zip'">
      <reload-button
        class="m-1"
        name="Metadata"
        :disable="!canTriggerMetadataExtraction || disableAction"
        :tooltip="
          informationTooltip(
            file.id == extractedFromFileId,
            canTriggerMetadataExtraction,
            'ExtractedMetadata'
          )
        "
        activateIndicator
        :size="props.buttonSize"
        :indicatorColor="file.id == extractedFromFileId"
        @clicked="
          triggerMetadataExtraction({
            fileId: file.id,
            publicationId: entityId,
          })
        "
      />
    </template>
  </template>

  <template v-if="props.file.nature === 'COVER'">
    <reload-button
      v-if="checkFileMime(props.file.mime) === 'cloudflareImagePackaging'"
      :name="'Cover'"
      :disable="!canTriggerRepackaging || disableAction"
      :activateIndicator="true"
      :indicatorColor="
        fileHasRepresentation(
          props.file.representations,
          'CloudflareImageRepresentation'
        )
      "
      :tooltip="
        informationTooltip(
          fileHasRepresentation(
            props.file.representations,
            'CloudflareImageRepresentation'
          ),
          canTriggerRepackaging,
          'CloudflareImageRepresentation'
        )
      "
      :size="props.buttonSize"
      @clicked="
        triggerCloudflareImageRepackaging({
          fileId: props.file.id,
        })
      "
      class="m-1"
    />
  </template>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification';
import { useMutation } from '@vue/apollo-composable';

import ReloadButton from '../base/ReloadButton.vue';
import { useAuth } from '../../auth';

import { graphql } from '../../__generated__/gql';
import { File, FileEdge } from '../../__generated__/graphql';

const props = defineProps<{
  file: {
    id: string;
    nature: string;
    mime: string;
    representations: Representation[];
  };
  buttonSize: string;
  entityId: string;
  extractedFromFileId: string | null;
  disableAction: boolean;
  fileList: any[] | null;
}>();

interface Representation {
  __typename: string;
}

const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();
const canTriggerRepackaging =
  canWriteResourceTypeOnAtLeastOneNamespace('files');

const canTriggerMetadataExtraction =
  canWriteResourceTypeOnAtLeastOneNamespace('publications');

const toast = useToast();
const popToast = (toastTitle: string, toastContent: string) => {
  toast(toastTitle.toUpperCase() + '\n' + toastContent);
};

const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const informationTooltip = (
  fileHasRepresentation: boolean,
  canTriggerRepack: boolean,
  representationTypeName: string
) => {
  if (!canTriggerRepack) {
    return 'User lacks the rights for this action';
  }

  let tooltipText = '';
  let isCover = false;
  let isMetadata = false;
  let isSample = false;

  switch (representationTypeName) {
    case 'AcsRepresentation':
      tooltipText = 'ACS';
      break;
    case 'LcpRepresentation':
      tooltipText = 'LCP';
      break;
    case 'CantookAudioRepresentation':
      tooltipText = 'CantookAudio';
      break;
    case 'CloudflareImageRepresentation':
      tooltipText = 'Cover Image';
      isCover = true;
      break;
    case 'ExtractedMetadata':
      tooltipText = 'Extracted metadata';
      isMetadata = true;
      break;
    case 'Sample':
      tooltipText = 'Sample';
      isSample = true;
      break;
    default:
      '';
  }

  if (fileHasRepresentation) {
    tooltipText = tooltipText.concat(
      isCover
        ? ' packed. <br>'
        : isSample
        ? ' generated.<br>'
        : isMetadata
        ? ' available.<br>'
        : ' package applied.<br>'
    );
  }

  if (!fileHasRepresentation) {
    tooltipText = tooltipText.concat(
      isCover
        ? ' not packed. <br>'
        : isSample
        ? ' not generated.<br>'
        : isMetadata
        ? ' not available.<br>'
        : ' package not applied.<br>'
    );
  }

  if (!props.disableAction) {
    tooltipText = tooltipText.concat(
      isCover
        ? ' Click to repack'
        : isSample
        ? ' Click to regenerate.'
        : isMetadata
        ? ' Click to trigger extraction.'
        : ' Click to reapply.'
    );
  }

  if (props.disableAction) {
    tooltipText = tooltipText.concat(
      (isSample ? 'Regeneration' : isMetadata ? 'Extraction' : 'Repackaging') +
        ' disabled because it is not the most recent file.'
    );
  }
  return tooltipText;
};

const fileHasRepresentation = (
  fileRepresentations: Representation[],
  representationTypename: string
) => {
  if (!fileRepresentations) {
    return false;
  }
  return fileRepresentations.find(
    (fileRepresentation) =>
      fileRepresentation.__typename === representationTypename
  )
    ? true
    : false;
};

const sampleIsGenerated = (
  fileList: FileEdge[] | null,
  contentFileId: string
) => {
  if (!fileList) {
    return false;
  }
  return fileList.some((file: FileEdge) => {
    return (
      file.node.nature === 'SAMPLE' &&
      file.node.derivedFrom?.id === contentFileId
    );
  });
};

const checkFileMime = (fileMime: string) => {
  if (fileMime === 'application/pdf' || fileMime === 'application/epub+zip') {
    return 'standardPackaging';
  } else if (fileMime === 'application/zip') {
    return 'audioPackaging';
  } else if (fileMime == 'image/jpeg' || fileMime == 'image/png') {
    return 'cloudflareImagePackaging';
  }
};

const TRIGGER_ACS_REPACKAGING = graphql(`
  mutation triggerAcsRepackaging($fileId: ID!) {
    triggerAcsRepackaging(fileId: $fileId) {
      success
    }
  }
`);

const TRIGGER_LCP_REPACKAGING = graphql(`
  mutation triggerLcpRepackaging($fileId: ID!) {
    triggerLcpRepackaging(fileId: $fileId) {
      success
    }
  }
`);

const TRIGGER_CANTOOKAUDIO_REPACKAGING = graphql(`
  mutation triggerCantookAudioRepackaging($fileId: ID!) {
    triggerCantookAudioRepackaging(fileId: $fileId) {
      success
    }
  }
`);

const TRIGGER_CLOUDFLAREIMAGE_REPACKAGING = graphql(`
  mutation triggerCloudflareImageRepackaging($fileId: ID!) {
    triggerCloudflareImageRepackaging(fileId: $fileId) {
      success
    }
  }
`);

const TRIGGER_EXTRACT_REGENERATION = graphql(`
  mutation triggerExtractRegeneration($fileId: ID!) {
    triggerExtractRegeneration(fileId: $fileId) {
      success
    }
  }
`);

const TRIGGER_METADATA_EXTRACTION = graphql(`
  mutation triggerMetadataExtraction($fileId: ID!, $publicationId: ID!) {
    triggerMetadataExtraction(fileId: $fileId, publicationId: $publicationId) {
      success
    }
  }
`);

const {
  mutate: triggerAcsRepackaging,
  loading: triggerAcsRepackagingLoading,
  error: triggerAcsRepackagingError,
  onDone: onTriggerAcsRepackagingDone,
  onError: onTriggerAcsRepackagingError,
} = useMutation(TRIGGER_ACS_REPACKAGING);

const {
  mutate: triggerLcpRepackaging,
  loading: triggerLcpRepackagingLoading,
  error: triggerLcpRepackagingError,
  onDone: onTriggerLcpRepackagingDone,
  onError: onTriggerLcpRepackagingError,
} = useMutation(TRIGGER_LCP_REPACKAGING);

const {
  mutate: triggerCantookAudioRepackaging,
  loading: triggerCantookAudioRepackagingLoading,
  error: triggerCantookAudioRepackagingError,
  onDone: onTriggerCantookAudioRepackagingDone,
  onError: onTriggerCantookAudioRepackagingError,
} = useMutation(TRIGGER_CANTOOKAUDIO_REPACKAGING);

const {
  mutate: triggerCloudflareImageRepackaging,
  loading: triggerCloudflareImageRepackagingLoading,
  error: triggerCloudflareImageRepackagingError,
  onDone: onTriggerCloudflareImageRepackagingDone,
  onError: onTriggerCloudflareImageRepackagingError,
} = useMutation(TRIGGER_CLOUDFLAREIMAGE_REPACKAGING);

const {
  mutate: triggerExtractRegeneration,
  loading: triggerExtractRegenerationLoading,
  error: triggerExtractRegenerationError,
  onDone: onTriggerExtractRegenerationDone,
  onError: onTriggerExtractRegenerationError,
} = useMutation(TRIGGER_EXTRACT_REGENERATION);

const {
  mutate: triggerMetadataExtraction,
  onDone: onTriggerMetadataExtractionDone,
  onError: onTriggerMetadataExtractionError,
} = useMutation(TRIGGER_METADATA_EXTRACTION);

onTriggerAcsRepackagingDone(() =>
  popToast('ACS packaging', 'will be applied within the next minute.')
);

onTriggerAcsRepackagingError((error) => {
  popErrorToast('Error', error.message);
});

onTriggerLcpRepackagingDone(() =>
  popToast('LCP packaging', 'will be applied within the next minute.')
);

onTriggerLcpRepackagingError((error) => {
  popErrorToast('Error', error.message);
});

onTriggerCantookAudioRepackagingDone(() =>
  popToast('Cantook Audio packaging', 'will be applied within the next minute.')
);

onTriggerCantookAudioRepackagingError((error) => {
  popErrorToast('Error', error.message);
});

onTriggerCloudflareImageRepackagingDone(() => {
  popToast(
    'Cloudflare Image packaging',
    'will be applied within the next minute.'
  );
});

onTriggerCloudflareImageRepackagingError((error) => {
  popErrorToast('Error', error.message);
});

onTriggerExtractRegenerationDone(() => {
  popToast('Sample regeneration', 'will be done within the next minute.');
});

onTriggerExtractRegenerationError((error) => {
  popErrorToast('Error', error.message);
});

onTriggerMetadataExtractionDone(() => {
  popToast('Metadata extraction', 'will be done within the next minute.');
});

onTriggerMetadataExtractionError((error) => {
  popErrorToast('Error', error.message);
});
</script>

<style></style>
